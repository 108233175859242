import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import {
  NEW_SETTLEMENT_VALUE,
  TRADE_METHOD_VALUE,
  ORDER_STATUSES,
  NEW_ORDER_TYPES,
  ALLOWED_STATUS_FOR_CHANGES,
  FX,
} from 'shared-modules/constants';
import {
  SORT_DESCENDING,
  SORT_ASCENDING,
  MAPPING_TABLE_COLUMN_NAMES_MAIN,
  DEFAULT_ORDERS_TABLE_SORT_BY,
  DEFAULT_ORDERS_TABLE_SORT_DIR,
  KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES,
  KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY,
  KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR,
} from 'shared-modules/constants/manualTrade';
import {
  expireTimeFunc,
  formatDateTimeWithoutDivider,
  getServiceQuantityUnit,
  saveDefaultValuesFromLocalStorage,
  saveJsonStyleDefaultValuesToLocalStorage,
} from 'shared-modules/services';
import PropTypes from 'prop-types';
import { removeSuffix } from 'shared-modules/hooks/symbol';
import {
  openManualTradeTableChangeOrder,
  deleteOrderRequest,
  openConfirmationModal,
  getOrdersTableDataRequest,
  changeOrdersTableMetaInfo,
} from '../../redux/actions';
import BuySellItem from '../BuySellItem';
import CustomButton from '../CustomButton';
import { ShortName } from '../ShortName';
import styles from './tableOrders.module.scss';
import { Table } from '../Table';
import {
  instrumentIdHeader,
  orderNameWithToolTips,
  commonDateSortHeader,
  orderStatusHeader,
  getTradeMethodHeader,
} from '../../services/tableTemplate';
import ValueWithPreservePrecision from '../ValueWithPreservePrecision';

const DEFAULT_SORTING = [
  {
    id: DEFAULT_ORDERS_TABLE_SORT_BY,
    desc: DEFAULT_ORDERS_TABLE_SORT_DIR,
  },
];

const TableOrders = ({ isManual }) => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const tableData = useSelector((state) => state.manualTrade.ordersData[serviceId]);
  const tableMetaInfo = useSelector((state) => state.manualTrade.ordersDataMetaInfo[serviceId]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));
  }, [dispatch, isManual, serviceId]);

  const getAnotherPartOfData = useCallback(() => {
    dispatch(getOrdersTableDataRequest({ isFirstData: false, isManual }));
  }, [dispatch, isManual]);

  const handleOpenOrderCancellationModal = useCallback(
    (orderId) => {
      dispatch(
        openConfirmationModal({
          title: '注文取消',
          bodyText:
            '注文を取消します。取消しする注文が自動売買注文の場合、当該自動売買は稼働停止となります。\n' +
            'また、自動売買の決済注文を取消す場合、当該自動売買が保有している建玉を手動決済いただく必要があります。よろしいですか。',
          buttonBackText: '戻る',
          buttonNextText: ' 取消確定',
          callback: () => {
            dispatch(deleteOrderRequest({ orderId }));
            dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));
          },
        }),
      );
    },
    [dispatch, isManual],
  );

  const handleOpenChangeOrderModal = useCallback(
    ({ orderId }) => {
      dispatch(openManualTradeTableChangeOrder({ orderId, isManual }));
    },
    [dispatch, isManual],
  );

  const changeIsActiveOrderSelected = useCallback(
    (e) => {
      batch(() => {
        dispatch(changeOrdersTableMetaInfo({ key: 'isActiveOrderSelected', value: Boolean(e), serviceId }));
        dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));
      });
    },
    [dispatch, isManual, serviceId],
  );

  const buttonsRenderFunction = useCallback(
    ({
      row: {
        original: { status, orderId },
      },
    }) => {
      return (
        <div className={styles.buttonContainer}>
          <>
            <CustomButton
              isSmall
              className={styles.firstButton}
              onClick={() => handleOpenChangeOrderModal({ orderId })}
              isDisabled={!ALLOWED_STATUS_FOR_CHANGES.includes(Number(status))}
            >
              変更
            </CustomButton>
            <CustomButton
              isSmall
              onClick={() => handleOpenOrderCancellationModal(orderId)}
              isDisabled={!ALLOWED_STATUS_FOR_CHANGES.includes(Number(status))}
            >
              取消
            </CustomButton>
          </>
        </div>
      );
    },
    [handleOpenChangeOrderModal, handleOpenOrderCancellationModal],
  );

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: instrumentIdHeader,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.ID,
        Cell: ({ cell: { value } }) => (serviceId === FX ? removeSuffix(value) : <ShortName instrumentId={value} />),
      },
      {
        Header: getTradeMethodHeader(serviceId, isManual),
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_METHOD.ID,
        Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
        disableSortBy: true,
      },
      {
        Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.SIDE.LABEL,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.SIDE.ID,
        Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
        disableSortBy: true,
      },
      {
        Header: (
          <span>
            新規
            <br />
            決済
          </span>
        ),
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.IS_CLOSE.ID,
        Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
        disableSortBy: true,
      },
      {
        Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.COMPOSITE_TYPE_NAME.LABEL,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.COMPOSITE_TYPE_NAME.ID,
        disableSortBy: true,
      },
      {
        Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.TYPE.LABEL,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.TYPE.ID,
        Cell: ({ cell: { value } }) => NEW_ORDER_TYPES[Number(value)],
        disableSortBy: true,
      },
      {
        Header: orderStatusHeader({ innerCallback: changeIsActiveOrderSelected }),
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.ID,
        Cell: ({ cell: { value } }) => ORDER_STATUSES[Number(value)],
      },
      {
        Header: `${MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.LABEL}(${getServiceQuantityUnit(serviceId)})`,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.QUANTITY.ID,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.PRICE.LABEL,
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.PRICE.ID,
        Cell: ({
          cell: {
            value,
            row: {
              original: { instrumentId },
            },
          },
        }) => {
          return <ValueWithPreservePrecision value={value} instrumentId={instrumentId} />;
        },
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: commonDateSortHeader({ label: MAPPING_TABLE_COLUMN_NAMES_MAIN.ORDER_TIME.LABEL }),
        accessor: MAPPING_TABLE_COLUMN_NAMES_MAIN.ORDER_TIME.ID,
        Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
        isNumber: true,
      },
      {
        Header: '注文名',
        accessor: 'apName',
        isOrderName: true,
        Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
        disableSortBy: true,
      },
      {
        Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.EXPIRE_TIME.LABEL,
        accessor: ({ expireType, expireTime }) => expireTimeFunc(expireType, expireTime),
        isNumber: true,
        disableSortBy: true,
      },
      {
        accessor: 'renderButtons',
        Header: '',
        Cell: buttonsRenderFunction,
        disableSortBy: true,
        sticky: 'right',
        minWidth: 120,
      },
    ],
    [buttonsRenderFunction, changeIsActiveOrderSelected, isManual, serviceId],
  );
  /* eslint-disable react/prop-types */

  const resized = useMemo(
    () => ({
      key: 'manualTradeOrderInquiry',
      default: [100, 72, 70, 60, 86, 87, 60, 60, 86, 100, 60, 60, 160],
    }),
    [],
  );

  const defaultSorting = useMemo(() => {
    if (tableMetaInfo.sortBy == null || tableMetaInfo.sortDir == null) {
      return DEFAULT_SORTING;
    }
    return [{ id: tableMetaInfo.sortBy, desc: tableMetaInfo.sortDir === SORT_DESCENDING }];
  }, [tableMetaInfo]);

  const filterHandler = useCallback(
    ({ key, value }) => {
      if (['tradeMethods', 'instrumentId', 'status'].includes(key)) {
        dispatch(changeOrdersTableMetaInfo({ key, value, serviceId }));
      }
      dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));

      const localStorageKey = KEY_FOR_DEFAULT_ORDERS_TABLE_FILTERING_VALUES[serviceId];

      saveJsonStyleDefaultValuesToLocalStorage({ key, value, localStorageKey });
      if (key === 'status') {
        saveDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_ORDERS_TABLE_IS_ACTIVE_ORDER_SELECTED[serviceId],
          value,
        });
      }
    },
    [dispatch, isManual, serviceId],
  );

  const sortingHandler = useCallback(
    ({ id: sortBy, desc: isDesc }) => {
      const sortDir = isDesc ? SORT_DESCENDING : SORT_ASCENDING;
      const { sortBy: previousSortBy, sortDir: previousSortDir } = tableMetaInfo;
      if (sortBy === previousSortBy && sortDir === previousSortDir) {
        return;
      }
      batch(() => {
        dispatch(changeOrdersTableMetaInfo({ key: 'sortBy', value: sortBy, serviceId }));
        dispatch(changeOrdersTableMetaInfo({ key: 'sortDir', value: sortDir, serviceId }));
        dispatch(getOrdersTableDataRequest({ isFirstData: true, isManual }));
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_BY[serviceId],
        value: sortBy,
      });
      saveDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_ORDERS_TABLE_SORT_DIR[serviceId],
        value: sortDir,
      });
    },
    [tableMetaInfo, serviceId, dispatch, isManual],
  );

  return (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      emptyText="注文がありません"
      tableMetaInfo={tableMetaInfo}
      paginationCallback={getAnotherPartOfData}
      defaultSorting={defaultSorting}
      useServerSorting
      sortingHandler={sortingHandler}
      filterHandler={filterHandler}
    />
  );
};

TableOrders.propTypes = {
  isManual: PropTypes.bool,
};

TableOrders.defaultProps = {
  isManual: false,
};

export default memo(TableOrders);
