import intersectionBy from 'lodash/intersectionBy';
import uniq from 'lodash/uniq';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_POSITIONS_MAX_AMOUNT, ETF } from 'shared-modules/constants';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { useIsMarkupTargetBatchClose } from 'shared-modules/hooks/markup';
import {
  openConfirmationModal,
  openErrorInfoModal,
  saveDeletingPositions,
  closeMultiplePositionsRequest,
} from '../../../../redux/actions';
import CustomButton from '../../../CustomButton';
import styles from './deletePositionsButton.module.scss';

const DeletePositionsButton = () => {
  const serviceId = useSelector((state) => state.auth.serviceId);
  const selectedPositions = useSelector((state) => state.manualTrade.selectedTableRows);
  const tableMetaInfo = useSelector((state) => state.manualTrade.positionsDataMetaInfo[serviceId]);
  const tableDataRaw = useSelector((state) => state.currencies.positions[serviceId]);

  const dispatch = useDispatch();

  const isNonePositionsSelected = selectedPositions.length === 0;

  const sumQuantityByInstrumentAndSide = useMemo(
    () =>
      selectedPositions.reduce((acc, position) => {
        const { instrumentId, side, quantity } = position;
        const existingEntry = acc.find((item) => item.instrumentId === instrumentId && item.side === side);
        if (existingEntry) {
          existingEntry.totalQuantity += quantity;
        } else {
          acc.push({ instrumentId, side, totalQuantity: quantity });
        }
        return acc;
      }, []),
    [selectedPositions],
  );

  const isMarkupTarget = useIsMarkupTargetBatchClose({ sumQuantityByInstrumentAndSide });

  const closeMultiPositions = useCallback(() => {
    const successDeletionCallback = () => {
      // filter all position that are already expired
      const stillDeletingPositions = selectedPositions.filter(
        (position) => position.deletionStartedAt && Date.now() - position.deletionStartedAt < 120000,
      );

      // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
      const positions = selectedPositions
        .map(({ positionId }) => ({ positionId, deletionStartedAt: Date.now() }))
        .concat(stillDeletingPositions);

      dispatch(saveDeletingPositions({ positions }));
    };

    dispatch(
      closeMultiplePositionsRequest({
        positionIds: selectedPositions.map((el) => el.positionId),
        callback: successDeletionCallback,
      }),
    );
  }, [dispatch, selectedPositions]);

  const openCloseMultiPositionsModal = useCallback(() => {
    // TODO CFD おそらく、ETFかそれ以外かの判定で問題ないはず
    const isETF = serviceId === ETF;
    let currenciesTypes = [];
    if (isETF) {
      currenciesTypes = uniq(
        intersectionBy(tableDataRaw, selectedPositions, 'positionId').map(
          (el) => el.instrumentId.match(/USD|JPY/)?.[0],
        ),
      );
    }
    const isBothUSDAndJPY = currenciesTypes.length === 2;

    if (selectedPositions.length > CLOSE_POSITIONS_MAX_AMOUNT) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: '建玉が100件を超えています。建玉を100件以内にしてから発注してください。',
        }),
      );
    } else if (isETF && isBothUSDAndJPY) {
      dispatch(
        openErrorInfoModal({
          title: 'エラー',
          message: '日本市場銘柄と米国市場銘柄を同時に選択することはできません',
        }),
      );
    } else {
      dispatch(
        openConfirmationModal({
          title: '一括決済注文',
          bodyText: (
            <>
              <p>
                選択した建玉の注文中の決済注文を取消し、全建玉数量を成行決済します。また稼働中の自動売買は停止いたします。よろしいですか？
                <br />
                大量の決済を行う場合は画面上の建玉が消えるまでに時間がかかる場合がございます。
              </p>
              {isMarkupTarget && (
                <div>
                  同一通貨ペア・同一注文手法・同一売買区分において、決済数量の合計が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは
                  <a
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      textDecorationStyle: 'solid',
                      textDecorationThickness: '0%',
                    }}
                    href={MARKUPS_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                </div>
              )}
            </>
          ),
          callback: closeMultiPositions,
          buttonBackText: '戻る',
          buttonNextText: '決済',
          isOverlap: true,
        }),
      );
    }
  }, [closeMultiPositions, dispatch, selectedPositions, serviceId, tableDataRaw, isMarkupTarget]);

  return (
    <CustomButton
      isSmall
      isDisabled={isNonePositionsSelected || tableMetaInfo.isLoading}
      onClick={openCloseMultiPositionsModal}
      className={styles.button}
      isBlack
    >
      一括決済
    </CustomButton>
  );
};

DeletePositionsButton.propTypes = {};

export default memo(DeletePositionsButton);
