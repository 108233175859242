/* eslint-disable import/no-unresolved */
import {
  put,
  call,
  takeEvery,
  take,
  fork,
  delay,
  select,
  cancel,
  takeLeading,
  takeLatest,
  retry,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import SockJs from 'sockjs-client';
// eslint-disable-next-line import/no-unresolved
import { ActivationState, Client } from '@stomp/stompjs';
import Decimal from 'decimal.js';
import { WS_API_URL } from '../../config';
import {
  BUY_SELL_MAIN,
  DEFAULT_DELAY,
  ETF,
  ETF_ADDITIONAL_INSTRUMENT_ID,
  FX,
  MARKET_STATUSES_VALUES,
  TRADE_TABLES,
  PORTFOLIO_ROUTE_NAME,
  TRADE_INFO_TABLES,
  CARD_STATUS_IDS,
  AP_GROUP_STATUSES,
  CFD,
  CFD_ADDITIONAL_INSTRUMENT_IDS,
  DEFAULT_QUANTITY_UNIT_CONV_FACTOR,
} from '../../constants';
import {
  DELAY_FOR_UPDATE_CURRENCY_PAIR,
  SOCKET_RETRY_DELAY,
  SUCCESSFULLY_CLOSE_SOCKET_CODE,
  ORDERS_SOCKET_MESSAGES,
  ACCOUNT_SOCKET_MESSAGES_FOR_UPDATE_MARGIN,
  SETTINGS_SOCKET_STATUSES,
  SETTINGS_SOCKET_TYPES,
  SETTINGS_SOCKET_SERVICES,
  SYSTEM_MAINTENANCE_STATUS,
  SYSTEM_NOT_MAINTENANCE_STATUS,
  LOSSCUT_EVENTS,
  LOSSCUT_IDS,
  LOSSCUT_STATUSES,
  RETRY_DELAY,
  RETRY_MAX_TRIES,
} from '../../constants/apiConstant';
import {
  SOCKET_CONNECTION_REQUEST,
  SOCKET_DISCONNECT_REQUEST,
  HANDLE_ORDER_SOCKET_MESSAGE,
  HANDLE_ACCOUNT_SOCKET_MESSAGE,
  HANDLE_SETTINGS_SOCKET_MESSAGE,
  RECONNECT_SOCKET_REQUEST,
  HANDLE_AP_SOCKET_MESSAGE,
  HANDLE_CART_ITEMS_SOCKET_MESSAGE,
  HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE,
} from '../actionConstants/socketConstants';
import { UPDATE_RATES_VALUES } from '../actionConstants/currenciesConstants';
import { getIdToken } from '../../api';
import { getCurrencyCartItemsCount } from '../../api/cartApi';
import { getPublicInitialRequests, getRefetchInitialRequests } from '../actions/authActions';
import { getExecutionsTableDataRequest, getOrdersTableDataRequest } from '../actions/manualTradeActions';
import {
  getRatesRequest,
  updateRatesValues,
  getRatesSuccess,
  getPositionsRequest,
  updateUnrealizedProfitLossValues,
  getPositionByIdRequest,
} from '../actions/currenciesActions';
import {
  getApGroupByIdRequest,
  getApGroupRequest,
  getExecutionsInfoRequest,
  getMarginRequest,
  getPlExecutionsInfoRequest,
  updateMarginBySocket,
} from '../actions/portfolioActions';
import {
  // getCurrentCartItemsCountRequest,
  getCurrentCartItemsCountSuccess,
  getCurrentCartRequest,
} from '../actions/cartActions';
import {
  handleOrderSocketMessage,
  handleAccountSocketMessage,
  handleSettingsSocketMessage,
  handleAPSocketMessage,
  handleCartItemsSocketMessage,
  openSocketErrorModal,
  closeSocketErrorModal,
  reconnectSocketRequest,
  socketConnectionRequest,
  handleTechnicalBuilderSocketMessage,
} from '../actions/socketActions';
import {
  changeLosscutStatus,
  changeMarketStatus,
  getAccountInfoRequest,
  getInstrumentListRequest,
  getSettingsRequest,
  updateSelectionMaintenanceStatus,
  updateServiceMaintenanceStatus,
} from '../actions/settingsActions';

import { date3MonthBefore, cutValueToPrecision, checkIsWebApp } from '../../services';
import { errorHandler } from './errorSaga';
import { updateOrder } from '../orders';
import { getOrder } from '../../api/orderApi';
import { getTechnicalBuilderDataRequest } from '../tech';
import { SCREEN_ID_TRADE } from '../../constants/screen';
import { getAccountFromIdToken } from './authSaga';
import { getSettingsHandler } from './settingsSaga';
import { updateLastStrategyRunTime } from '../../api/strategyApi';
import Logger from '../../services/Logger';

let currencyPairBuffer = [];
let updateCurrencyPairHandlerTask;

const stompOptions = { reconnectDelay: 0 };

let stompClient;

function initSocket({ isPublic, token }) {
  if (stompClient) {
    Logger.error('stompClient already created.');
  }

  if (!isPublic) {
    stompClient = new Client({
      connectHeaders: {
        token,
      },
      ...stompOptions,
    });
  } else {
    stompClient = new Client(stompOptions);
  }

  const subscribeInstances = [];
  let onConnectTimer;
  return eventChannel((emitter) => {
    stompClient.webSocketFactory = () => new SockJs(`${WS_API_URL}${!isPublic ? `?token=${token}` : ''}`);

    stompClient.onConnect = () => {
      // setTimeout before subscription, happen only once, to avoid "Connection hasn't been established"
      onConnectTimer = setTimeout(() => {
        try {
          if (isPublic) {
            // for public sockets
            subscribeInstances.push(
              stompClient.subscribe('/public/rate', (message) => {
                const parsedMessage = JSON.parse(message.body);

                emitter(updateRatesValues({ currencyRates: parsedMessage }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/public/system', (message) => {
                const { type, status, service: serviceId, instrumentId } = JSON.parse(message.body);

                emitter(handleSettingsSocketMessage({ type, status, serviceId, instrumentId }));
              }),
            );
          } else {
            // for secured sockets
            subscribeInstances.push(
              stompClient.subscribe('/topic/rate', (message) => {
                const parsedMessage = JSON.parse(message.body);

                emitter(updateRatesValues({ currencyRates: parsedMessage }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/user/queue/order', (message) => {
                const parsedMessage = JSON.parse(message.body);

                emitter(handleOrderSocketMessage({ message: parsedMessage }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/user/queue/ap', (message) => {
                const { apGroupId, service: serviceId, technicalBuilderId, event } = JSON.parse(message.body);

                emitter(handleAPSocketMessage({ apGroupId, serviceId, technicalBuilderId, event }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/user/queue/technicalBuilder', (message) => {
                const { apGroupId, serviceId, status } = JSON.parse(message.body);
                emitter(handleTechnicalBuilderSocketMessage({ apGroupId, serviceId, status }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/user/queue/cart', (message) => {
                const { itemCount } = JSON.parse(message.body);

                emitter(handleCartItemsSocketMessage({ itemCount }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/user/queue/account', (message) => {
                const { event, service: serviceId } = JSON.parse(message.body);

                emitter(handleAccountSocketMessage({ event, serviceId }));
              }),
            );

            subscribeInstances.push(
              stompClient.subscribe('/topic/system', (message) => {
                const { type, status, service: serviceId, instrumentId } = JSON.parse(message.body);

                emitter(handleSettingsSocketMessage({ type, status, serviceId, instrumentId }));
              }),
            );
          }
        } catch (err) {
          // "subscribe of null" error will come, likely to happen on expired session, or login sso with expired token
          // the websocket has reconnect attemp, implemented below so should be fine
        }
      }, DEFAULT_DELAY);

      emitter(closeSocketErrorModal());
    };

    stompClient.onWebSocketClose = (e) => {
      if (e.code !== SUCCESSFULLY_CLOSE_SOCKET_CODE) {
        console.log('error stompClient.onWebSocketClose: ', e); // eslint-disable-line
        emitter(reconnectSocketRequest({ isPublic }));
      }
    };
    stompClient.onWebSocketError = (e) => {
      console.log('error stompClient.onWebSocketError: ', e); // eslint-disable-line
      setTimeout(() => {
        emitter(reconnectSocketRequest({ isPublic }));
      }, SOCKET_RETRY_DELAY); // setTimeout here to prevent immediate error & retries on logout
    };
    stompClient.onStompError = (e) => {
      console.log('error stompClient.onStompError: ', e); // eslint-disable-line
      emitter(reconnectSocketRequest({ isPublic }));
    };

    stompClient.activate();

    const thisStompClient = stompClient;
    return () => {
      thisStompClient.onConnect = () => {};
      thisStompClient.onWebSocketClose = () => {};
      thisStompClient.onWebSocketError = () => {};
      thisStompClient.onStompError = () => {};
      clearTimeout(onConnectTimer);
      subscribeInstances.forEach((subscriber) => subscriber.unsubscribe());
    };
  });
}

function* updateCurrencyPairHandler({ isPublic }) {
  try {
    const ETFAccountExist = yield select((state) => state.auth.loginValues.etfAccount);
    const CFDAccountExist = yield select((state) => state.auth.loginValues.cfdAccount);

    while (true) {
      yield delay(DELAY_FOR_UPDATE_CURRENCY_PAIR);
      if (!stompClient || !stompClient.connected) {
        continue; // eslint-disable-line
      }

      const bufferLength = currencyPairBuffer.length;
      const newCurrencyPairsArray = currencyPairBuffer.splice(0, bufferLength);

      // skip other steps if there are no new values
      // if (!newCurrencyPairsArray.length) {
      //   continue; // eslint-disable-line
      // }

      const updatedRates = newCurrencyPairsArray.reduce((acc, newCurrencyPair) => {
        const prevValuesIndex = acc.findIndex(
          (accumulatedItem) => accumulatedItem.instrumentId === newCurrencyPair.instrumentId,
        );
        if (prevValuesIndex === -1) {
          acc.push(newCurrencyPair);
        } else {
          acc[prevValuesIndex] = newCurrencyPair;
        }
        return acc;
      }, []);

      const rates = yield select((state) => state.currencies.rates);

      const newRates = updatedRates.reduce(
        (acc, item) => {
          if (acc[item.instrumentId]) {
            acc[item.instrumentId] = {
              ...rates[item.instrumentId],
              ask: item.ask,
              bid: item.bid,
              time: item.time,
              askHigh: rates[item.instrumentId].askHigh < item.ask ? item.ask : rates[item.instrumentId].askHigh,
              askLow: rates[item.instrumentId].askLow > item.ask ? item.ask : rates[item.instrumentId].askLow,
              bidHigh: rates[item.instrumentId].bidHigh < item.bid ? item.bid : rates[item.instrumentId].bidHigh,
              bidLow: rates[item.instrumentId].bidLow > item.bid ? item.bid : rates[item.instrumentId].bidLow,
              previousAsk: rates[item.instrumentId].ask,
              previousBid: rates[item.instrumentId].bid,
            };
          }
          return acc;
        },
        { ...rates },
      );

      yield put(getRatesSuccess({ rates: newRates }));

      // skip others steps for public sockets
      if (isPublic) {
        continue; // eslint-disable-line
      }

      const positionsUnrealizedProfitLoss = yield select((state) => state.currencies.positionsUnrealizedProfitLoss);
      if (!Object.keys(rates).length) {
        continue; // eslint-disable-line
      }

      const currentServiceId = yield select((state) => state.auth.serviceId);

      const marginLoading = yield select((state) => state.portfolio.changingSummaryInfoLoading);
      const positionLoading = yield select(
        (state) => state.manualTrade.positionsDataMetaInfo[currentServiceId].loading,
      );
      const instrumentList = yield select((state) => state.settings.instrumentList);
      if (marginLoading || positionLoading) continue; // eslint-disable-line

      const result = Object.entries(positionsUnrealizedProfitLoss).reduce(
        (resultObject, [instrumentId, positionsObject]) => {
          Object.entries(positionsObject).forEach(([positionId, positionInfo]) => {
            if (!resultObject.newPositionsUnrealizedProfitLoss[instrumentId]) {
              // eslint-disable-next-line no-param-reassign
              resultObject.newPositionsUnrealizedProfitLoss[instrumentId] = {};
            }

            if (!rates[instrumentId]?.ask) {
              return;
            }

            const calculatedPriceDifference =
              Number(positionInfo.side) === BUY_SELL_MAIN.SELL.ID
                ? new Decimal(positionInfo.tradePrice).sub(rates[instrumentId].ask)
                : new Decimal(rates[instrumentId].bid).sub(positionInfo.tradePrice);

            let convertedToYenMultiplier;
            const quantityMultiplier =
              instrumentList?.[instrumentId]?.quantityUnitConvFactor ?? DEFAULT_QUANTITY_UNIT_CONV_FACTOR;

            const conversionInstrumentId = instrumentList?.[instrumentId]?.conversionInstrumentId;

            if (positionInfo.serviceId === FX) {
              if (!conversionInstrumentId) {
                convertedToYenMultiplier = 1;
              } else {
                convertedToYenMultiplier = new Decimal(rates[conversionInstrumentId].bid)
                  .add(rates[conversionInstrumentId].ask)
                  .div(2);
              }
            } else if (positionInfo.serviceId === ETF) {
              if (!conversionInstrumentId) {
                convertedToYenMultiplier = 1;
              } else {
                convertedToYenMultiplier = new Decimal(rates[`${ETF}.${conversionInstrumentId}`].bid)
                  .add(rates[`${ETF}.${conversionInstrumentId}`].ask)
                  .div(2);
              }
            } else if (positionInfo.serviceId === CFD) {
              if (!conversionInstrumentId) {
                convertedToYenMultiplier = 1;
              } else {
                // 円転レート用の通貨ペアは必ず存在すること前提
                const symbol = `${CFD}.${conversionInstrumentId}`;
                convertedToYenMultiplier = new Decimal(rates[symbol].bid).add(rates[symbol].ask).div(2);
              }
            }

            const unrealizedProfitLoss = Number(
              cutValueToPrecision(
                new Decimal(positionInfo.quantity)
                  .mul(quantityMultiplier)
                  .mul(calculatedPriceDifference)
                  .mul(convertedToYenMultiplier),
                0,
              ),
            );

            // eslint-disable-next-line no-param-reassign
            resultObject.newPositionsUnrealizedProfitLoss[instrumentId][positionId] = {
              ...positionInfo,
              unrealizedProfitLoss,
            };

            // eslint-disable-next-line no-param-reassign
            resultObject[positionInfo.serviceId].sumOfUnrealizedProfitLoss =
              resultObject[positionInfo.serviceId].sumOfUnrealizedProfitLoss.add(unrealizedProfitLoss);
          });

          return resultObject;
        },
        {
          newPositionsUnrealizedProfitLoss: {},
          [FX]: { sumOfUnrealizedProfitLoss: new Decimal(0) },
          [ETF]: { sumOfUnrealizedProfitLoss: new Decimal(0) },
          [CFD]: { sumOfUnrealizedProfitLoss: new Decimal(0) },
        },
      );
      yield put(
        updateUnrealizedProfitLossValues({ positionsUnrealizedProfitLoss: result.newPositionsUnrealizedProfitLoss }),
      );

      yield put(
        updateMarginBySocket({ unrealizedProfitLoss: result[FX].sumOfUnrealizedProfitLoss.toNumber(), serviceId: FX }),
      );

      if (ETFAccountExist)
        yield put(
          updateMarginBySocket({
            unrealizedProfitLoss: result[ETF].sumOfUnrealizedProfitLoss.toNumber(),
            serviceId: ETF,
          }),
        );

      if (CFDAccountExist) {
        yield put(
          updateMarginBySocket({
            unrealizedProfitLoss: result[CFD].sumOfUnrealizedProfitLoss.toNumber(),
            serviceId: CFD,
          }),
        );
      }
    }
  } catch (e) {
    console.log('error updateCurrencyPairHandler: ', e); // eslint-disable-line
  } finally {
    currencyPairBuffer = [];
  }
}

function* socketListener(action) {
  let channel;
  try {
    const { payload: { isPublic } = {} } = action;
    let token;
    if (!isPublic) {
      token = yield call(getIdToken);
    }
    // 非同期だと非同期中にキャンセルされても処理が続行されるため、initSocketはasyncにしない
    channel = yield call(initSocket, { isPublic, token });
    updateCurrencyPairHandlerTask = yield fork(updateCurrencyPairHandler, { isPublic });

    while (true) {
      const socketAction = yield take(channel);
      if (socketAction.type === UPDATE_RATES_VALUES) {
        const { currencyRates } = socketAction.payload;
        const { service, instrumentId } = currencyRates;
        if (service === ETF && instrumentId === ETF_ADDITIONAL_INSTRUMENT_ID) {
          currencyPairBuffer.push({
            ...currencyRates,
            instrumentId: `${ETF}.${instrumentId}`,
          });
        } else if (service === CFD && CFD_ADDITIONAL_INSTRUMENT_IDS[instrumentId]) {
          currencyPairBuffer.push({
            ...currencyRates,
            instrumentId: `${CFD}.${instrumentId}`,
          });
        } else {
          currencyPairBuffer.push(currencyRates);
        }
      } else {
        yield put(socketAction);
      }
    }
  } catch (e) {
    console.log('error socketListener: ', e); // eslint-disable-line
  } finally {
    // takeLatestによりcancelされると処理が中断されfinallyにとぶ
    channel?.close();
    if (stompClient?.state === ActivationState.ACTIVE) {
      // activeになる前にdeactivateするとwarnログが出るためステータスをチェック
      stompClient?.deactivate();
    }
    stompClient = null;
    yield cancel(updateCurrencyPairHandlerTask);
  }
}

function* reconnectSocketRequestHandler(action) {
  try {
    const { payload: { isPublic } = {} } = action;
    yield put(openSocketErrorModal({ isPublic }));

    while (true) {
      yield delay(SOCKET_RETRY_DELAY);

      const hasError = yield select((state) => state.socket.hasError);
      if (!hasError) {
        if (isPublic) {
          yield put(getRatesRequest({ isPublic: true }));
        } else {
          yield put(getInstrumentListRequest({ isRefetch: true }));
          yield put(getRatesRequest({ isRefetch: true }));
          yield put(getPositionsRequest());
          const serviceId = yield select((state) => state.auth.serviceId);

          yield put(getMarginRequest({ serviceId }));
        }
        break;
      }

      yield put(socketConnectionRequest({ isPublic }));
    }
  } catch (e) {
    console.log('error reconnectSocketRequestHandler: ', e); // eslint-disable-line
  } finally {
    const hasError = yield select((state) => state.socket.hasError);
    if (hasError) {
      Logger.error(new Error('reconnectSocketRequestHandler was canceled'));
    }
  }
}

function* disconnectSocketHandler() {
  try {
    if (updateCurrencyPairHandlerTask) {
      yield cancel(updateCurrencyPairHandlerTask);
    }

    if (stompClient) {
      stompClient.deactivate();
    }
  } catch (e) {
    console.log('error disconnectSocketHandler: ', e); // eslint-disable-line
  }
}

function* orderSocketMessageHandler(action) {
  try {
    const {
      payload: { message },
    } = action;

    const serviceId = message.service;
    const isWebApp = checkIsWebApp();

    const currentServiceLosscut = yield select((state) => state.settings.accountInfo[serviceId]?.losscutStatus);
    const isCurrentServiceLosscut = currentServiceLosscut === LOSSCUT_STATUSES.START.ID;

    if (!serviceId || isCurrentServiceLosscut) {
      return;
    }

    yield put(getMarginRequest({ serviceId }));

    const currentTable = yield select((state) => state.manualTrade.selectedTable);
    const currentInfoTable = yield select((state) => state.manualTrade.selectedInfoTable);

    const { id: portfolioOpenCardApGroupId, activeApCount } = yield select(
      (state) => state.portfolio.selectedApGroupData,
    );

    if (
      (currentTable === TRADE_TABLES.EXECUTIONS.ID || currentInfoTable === TRADE_INFO_TABLES.EXECUTIONS.ID) &&
      message.status === ORDERS_SOCKET_MESSAGES.FILLED
    ) {
      // 現状mobileではcurrentInfoTableのみを使用している、かつcurrentTableの初期値は1なのでisWebAppのチェックは無しでも問題ないが
      // 初期値が0になったときの考慮のためisWebAppの条件を付与する
      const isManual = isWebApp && currentTable === TRADE_TABLES.EXECUTIONS.ID;
      yield put(getExecutionsTableDataRequest({ isFirstData: true, isManual }));
    }

    if (message.status === ORDERS_SOCKET_MESSAGES.FILLED && message.apGroupId === portfolioOpenCardApGroupId) {
      yield put(getExecutionsInfoRequest({ apGroupId: portfolioOpenCardApGroupId, isFirstData: true, serviceId }));
      yield put(
        getPlExecutionsInfoRequest({
          apGroupId: portfolioOpenCardApGroupId,
          fromDate: date3MonthBefore(),
          toDate: new Date(),
        }),
      );
    }

    if (message.apGroupId && message.apGroupId === portfolioOpenCardApGroupId) {
      yield put(
        getApGroupByIdRequest({
          id: message.apGroupId,
          serviceId,
          doNotReload: true,
          status: activeApCount === 0 ? 0 : 1,
        }),
      );
    }

    const positionIsLoading = yield select((state) => state.manualTrade.positionsDataMetaInfo[serviceId].loading);
    if (
      message.status === ORDERS_SOCKET_MESSAGES.FILLED ||
      message.status === ORDERS_SOCKET_MESSAGES.CANCELED ||
      message.status === ORDERS_SOCKET_MESSAGES.ACTIVE ||
      message.status === ORDERS_SOCKET_MESSAGES.NEW ||
      message.status === ORDERS_SOCKET_MESSAGES.EXPIRED
    ) {
      if (!positionIsLoading) {
        yield put(
          getPositionByIdRequest({
            orderId: message.orderId,
            status: message.status,
            closePositionId: message.closePositionId,
            closePositionQuantity: message.quantity,
            serviceId,
          }),
        );
      }
    }

    if (message.status !== ORDERS_SOCKET_MESSAGES.EXECUTING) {
      const { data: orderData } = yield call(getOrder, serviceId, message.orderId);
      yield put(updateOrder({ serviceId, orderData }));
    }

    const getUserLocation = isWebApp
      ? yield select((state) => state.router.location.pathname)
      : yield select((state) => state.mobileServices.activeMobileScreen);
    const isPortfolio = isWebApp
      ? getUserLocation === PORTFOLIO_ROUTE_NAME.WEB
      : getUserLocation === PORTFOLIO_ROUTE_NAME.MOBILE;

    const apGroupsData = yield select((state) => state.portfolio.apGroupsData[message.service]);
    const isRelatedToApGroup = apGroupsData.find((group) => group.id === message.apGroupId);

    // update only one particular card when receiving a new position for APGroup only
    if (message.status === ORDERS_SOCKET_MESSAGES.NEW && isPortfolio && isRelatedToApGroup) {
      yield put(getApGroupRequest({ groupId: message.apGroupId, serviceId: message.service }));
    }

    if (currentTable === TRADE_TABLES.ORDERS.ID || currentInfoTable === TRADE_INFO_TABLES.ORDERS.ID) {
      const screen = yield select((state) => state.screen.screen);
      const isManual = isWebApp && screen === SCREEN_ID_TRADE;
      yield put(getOrdersTableDataRequest({ isFirstData: true, isManual }));
    }
  } catch (e) {
    console.log('error updateTablesBySocketRequestHandler: ', e); // eslint-disable-line
  }
}

function* accountSocketMessageHandler(action) {
  try {
    const {
      payload: { event, serviceId },
    } = action;

    if (LOSSCUT_EVENTS.includes(event)) yield put(changeLosscutStatus({ status: LOSSCUT_IDS[event], serviceId }));

    const currentServiceId = yield select((state) => state.auth.serviceId);
    const currentServiceLosscut = yield select((state) => state.settings.accountInfo[currentServiceId]?.losscutStatus);
    const isCurrentServiceLosscut = currentServiceLosscut === LOSSCUT_STATUSES.START.ID;

    if (serviceId !== currentServiceId || isCurrentServiceLosscut) {
      // when this event is "asset changed event", then load state of margin.
      if (ACCOUNT_SOCKET_MESSAGES_FOR_UPDATE_MARGIN.includes(event)) {
        yield put(getMarginRequest({ serviceId }));
      }
      return;
    }

    // when this event is "asset changed event", then load state of margin.
    if (ACCOUNT_SOCKET_MESSAGES_FOR_UPDATE_MARGIN.includes(event)) {
      yield put(getMarginRequest({ serviceId }));
    }

    // when this event is "losscut end event", then load transaction datas.
    if (LOSSCUT_STATUSES.END.event.includes(event)) {
      /* load positions. */
      const positionIsLoading = yield select(
        (state) => state.manualTrade.positionsDataMetaInfo[currentServiceId].loading,
      );
      if (!positionIsLoading) {
        yield put(getPositionsRequest());
      }

      /* load trade tables. */
      const currentTable = yield select((state) => state.manualTrade.selectedTable);
      // load executions.
      if (currentTable === TRADE_TABLES.EXECUTIONS.ID) {
        yield put(getExecutionsTableDataRequest({ isFirstData: true }));
      }
      // load orders.
      const isWebApp = checkIsWebApp();
      if (currentTable === TRADE_TABLES.ORDERS.ID) {
        // 現在選択しているサービスのロスカットが解消されたタイミングで、
        // トレードの注文照会タブが表示されている場合にこのブロックが実行される
        const screen = yield select((state) => state.screen.screen);
        const isManual = isWebApp && screen === SCREEN_ID_TRADE;
        yield put(getOrdersTableDataRequest({ isFirstData: true, isManual }));
      }

      /* load portfolio. */
      const getUserLocation = isWebApp
        ? yield select((state) => state.router.location.pathname)
        : yield select((state) => state.mobileServices.activeMobileScreen);
      const isPortfolio = isWebApp
        ? getUserLocation === PORTFOLIO_ROUTE_NAME.WEB
        : getUserLocation === PORTFOLIO_ROUTE_NAME.MOBILE;
      // when user opens portfolio, then load portfolio datas.
      if (isPortfolio) {
        // load all ap groups.
        yield put(getApGroupRequest());
        const {
          apGroupId,
          serviceId: apServiceId,
          activeApCount,
        } = yield select((state) => state.portfolio.selectedApGroupData);
        if (apGroupId) {
          // load selected ap group data.
          yield put(
            getApGroupByIdRequest({
              id: apGroupId,
              doNotReload: false,
              apServiceId,
              status: activeApCount === 0 ? 0 : 1,
            }),
          );
          // load execution info initial datas.
          yield put(getExecutionsInfoRequest({ apGroupId, isFirstData: true, serviceId }));
          yield put(
            getPlExecutionsInfoRequest({
              apGroupId,
              fromDate: date3MonthBefore(),
              toDate: new Date(),
            }),
          );
        }
      }
    }
  } catch (e) {
    console.log('error accountSocketMessageHandler: ', e); // eslint-disable-line
  }
}

function* settingsSocketHandler(action) {
  try {
    const {
      payload: { type, status, serviceId, instrumentId },
    } = action;

    const isMaintenanceStart = status === SETTINGS_SOCKET_STATUSES.START;

    // change auto-select status
    if (serviceId === SETTINGS_SOCKET_SERVICES.SELECTION && type === SETTINGS_SOCKET_TYPES.MAINTENANCE) {
      const maintenanceStatus = isMaintenanceStart ? SYSTEM_MAINTENANCE_STATUS : SYSTEM_NOT_MAINTENANCE_STATUS;

      yield put(updateSelectionMaintenanceStatus({ status: maintenanceStatus }));
      return;
    }

    // change market status
    if (type === SETTINGS_SOCKET_TYPES.MARKET) {
      yield put(changeMarketStatus({ serviceId, instrumentId, status: MARKET_STATUSES_VALUES[status] }));

      if (serviceId === FX && status === SETTINGS_SOCKET_STATUSES.OPEN) {
        yield put(getInstrumentListRequest({ isRefetch: true }));
        yield put(getRatesRequest({ isRefetch: true }));
      }
    }

    // change maintenance status
    if (type === SETTINGS_SOCKET_TYPES.MAINTENANCE) {
      yield put(updateServiceMaintenanceStatus({ serviceId, isMaintenance: isMaintenanceStart }));
      const isAuth = yield select((state) => state.auth.isAuthenticated);
      if (isAuth) {
        if (!isMaintenanceStart) {
          yield* getAccountFromIdToken();
          yield put(getInstrumentListRequest({ isRefetch: true }));
          yield* getSettingsHandler(getSettingsRequest({ isRefetch: true }));
          yield put(getAccountInfoRequest({ isRefetch: true }));
          yield put(getRatesRequest());
        } else {
          yield put(getRefetchInitialRequests());
        }
      } else {
        yield put(getPublicInitialRequests());
      }
    }
  } catch (e) {
    console.log('error settingsSocketHandler: ', e); // eslint-disable-line
  }
}

function* APSocketHandler(action) {
  try {
    const {
      payload: { apGroupId, technicalBuilderId, event, serviceId },
    } = action;
    try {
      // TODO matsuzaki100983 レコメンドの対象をFXに固定しているので全アセット対象とする場合は修正
      // 自動売買作成イベントが発生したら自動売買最終稼働時間を更新する
      if (event === 'created' && serviceId === FX) {
        const userId = yield select((state) => state.auth.portalId);
        yield call(updateLastStrategyRunTime, { userId });
      }
    } catch (e) {
      Logger.error(e);
    }
    const { id: portfolioOpenCardApGroupId, serviceId: openCardserviceId } = yield select(
      (state) => state.portfolio.selectedApGroupData,
    );

    const currentServiceLosscut = yield select((state) => state.settings.accountInfo[openCardserviceId]?.losscutStatus);
    const isCurrentServiceLosscut = currentServiceLosscut === LOSSCUT_STATUSES.START.ID;

    if (isCurrentServiceLosscut) {
      return;
    }

    if (apGroupId && technicalBuilderId) {
      yield put(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[[AP_GROUP_STATUSES.ALL_STATUSES]] }));
      yield put(getApGroupRequest({ serviceId }));
    }

    // update individual card if ap group is not deleted
    if (['activated', 'deactivated'].includes(event)) {
      if (apGroupId === portfolioOpenCardApGroupId) {
        if (event === 'activated') {
          yield put(getApGroupByIdRequest({ id: apGroupId, doNotReload: true, serviceId, status: 1 }));
        } else if (event === 'deactivated') {
          yield put(
            getApGroupByIdRequest({
              id: apGroupId,
              doNotReload: true,
              serviceId,
            }),
          );
        }
      } else if (apGroupId !== portfolioOpenCardApGroupId) {
        if (event === 'activated') {
          yield put(
            getApGroupByIdRequest({
              id: apGroupId,
              doNotReload: true,
              serviceId,
              status: 1,
              doNotUpdateSelectedData: true,
            }),
          );
        } else if (event === 'deactivated') {
          yield put(
            getApGroupByIdRequest({
              id: apGroupId,
              doNotReload: true,
              serviceId,
              doNotUpdateSelectedData: true,
            }),
          );
        }
      }
    }

    const isWebApp = checkIsWebApp();
    const getUserLocation = isWebApp
      ? yield select((state) => state.router.location.pathname)
      : yield select((state) => state.mobileServices.activeMobileScreen);
    const isPortfolio = isWebApp
      ? getUserLocation === PORTFOLIO_ROUTE_NAME.WEB
      : getUserLocation === PORTFOLIO_ROUTE_NAME.MOBILE;

    if (isPortfolio && event === 'updated') {
      yield put(getApGroupByIdRequest({ id: apGroupId, doNotReload: true, serviceId: openCardserviceId, status: 1 }));
    } else if (isPortfolio && event === 'allStopped') {
      yield put(getApGroupByIdRequest({ id: apGroupId, doNotReload: false, serviceId: openCardserviceId, status: 0 }));
    }
  } catch (e) {
    console.log('error APSocketHandler: ', e); // eslint-disable-line
  }
}

function* TechnicalBuilderSocketHandler(action) {
  try {
    const {
      payload: { status },
    } = action;
    // technicalBuidler became inactive
    if (status === 'deactivated') {
      yield put(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[AP_GROUP_STATUSES.ALL_STATUSES] }));
    }
  } catch (e) {
    console.log('error TechnicalBuilderSocketHandler: ', e); // eslint-disable-line
  }
}

function* cartItemsSocketHandler(_action) {
  try {
    // const {
    //   payload: { itemCount },
    // } = action;

    const defaultSelectionTermId = yield select((state) => state.constants.defaultSelectionTermId);
    const previousItemCount = yield select((state) => state.cart.currentDataItemsCount);

    // TODO 並行稼働期間は互換性維持のため、通知受信のたびにデータを取り直すが、
    // 並行稼働期間が完了した暁には通知のペイロードをそのまま利用するよう修正する
    // if (itemCount !== previousItemCount) {
    //   yield put(getCurrentCartRequest({ termId: defaultSelectionTermId }));
    //   yield put(getCurrentCartItemsCountRequest());
    //   yield put(getCurrentCartItemsCountSuccess({ itemsCount: itemCount }));
    // }
    try {
      const { data: itemsCount } = yield retry(RETRY_MAX_TRIES, RETRY_DELAY, getCurrencyCartItemsCount);
      if (itemsCount !== previousItemCount) {
        yield put(getCurrentCartRequest({ termId: defaultSelectionTermId }));
      }
      yield put(getCurrentCartItemsCountSuccess({ itemsCount }));
    } catch (e) {
      yield call(errorHandler, { error: e });
    }
  } catch (e) {
    // empty
    console.log('cartItemsSocketHandler error: ', e); // eslint-disable-line
  }
}

function* socketSagaHandler() {
  yield takeLatest(SOCKET_CONNECTION_REQUEST, socketListener);
  yield takeLeading(RECONNECT_SOCKET_REQUEST, reconnectSocketRequestHandler);
  yield takeEvery(SOCKET_DISCONNECT_REQUEST, disconnectSocketHandler);
  yield takeEvery(HANDLE_ORDER_SOCKET_MESSAGE, orderSocketMessageHandler);
  yield takeEvery(HANDLE_ACCOUNT_SOCKET_MESSAGE, accountSocketMessageHandler);
  yield takeEvery(HANDLE_SETTINGS_SOCKET_MESSAGE, settingsSocketHandler);
  yield takeEvery(HANDLE_AP_SOCKET_MESSAGE, APSocketHandler);
  yield takeEvery(HANDLE_TECHNICAL_BUILDER_SOCKET_MESSAGE, TechnicalBuilderSocketHandler);
  yield takeEvery(HANDLE_CART_ITEMS_SOCKET_MESSAGE, cartItemsSocketHandler);
}

export default socketSagaHandler;
