import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SIZES } from 'shared-modules/constants';
import Modal from '../../../../components/Modal';
import CustomButton from '../../../../components/CustomButton';
import styles from './autoSelectWarningInfo.module.scss';

const BUTTON_WIDTH = 312;

// Text don't have breaks, because React would add spaces in place of breaks, which is undesirable
const AutoSelectWarningInfo = ({ isOpen, closeModal, termEndDate, isLabPreview }) => (
  <Modal size={MODAL_SIZES.WIDTH_640} isOpen={isOpen} closeModal={closeModal} title="注意事項" isOverlap>
    <div className={styles.bodyWrapper}>
      <div className={styles.text}>
        収益率､総合損益等はシミュレーションの結果であり､将来の利益を保証するものではありません｡
      </div>
      <div className={styles.header}>シミュレーションデータについて</div>
      <div className={styles.text}>
        本機能は､過去のFX・CFD・ETFの1分足レートをもとにスプレッド､スワップポイント(FX)
        ､手数料(FX)､金利・配当相当額(CFD)､金利・分配相当額(ETF)を加味した数値でバックテストを行った結果を表示しています｡
        将来の結果を保証するものではありませんのでご注意ください｡また､バックテストは実際の取引と異なります｡
        {isLabPreview &&
          `ビルダーズシェアロジックにおける「稼働日」は､ビルダーズシェアのポートフォリオに含まれる個々のロジックにおける初回稼働日のうち､最も若い日付を採用します｡`}
        <br />
        更新日:
        {` ${termEndDate}`}
      </div>
      <div className={styles.header}>ロジックの説明動画について</div>
      <div className={styles.text}>ロジックの説明動画はイメージです。</div>
      <div className={styles.header}>自動売買ルールについて</div>
      <div className={styles.text}>
        各自動売買ルールは不定期に入れ替えやロジック設定値の更新をおこないます｡
        セレクト一覧には､常に最新パラメーターの自動売買セレクトが表示されます｡
      </div>
      <div className={styles.text}>
        一部の自動売買ルールは､両建てとなるタイミングがあるため､スプレッドや金利(CFD･ETF)が二重にかかる
        ､スワップポイント(FX)で支払いが生じるなどのデメリットがございます｡
        また､一部のルールを除いて､それぞれの自動売買注文に損切りの設定はされていません｡注文内容の詳細は各ルールの注文設定からご確認ください｡
      </div>
      <div className={styles.text}>上記をご理解いただき､ご自身の責任と判断でご利用ください｡</div>
      <CustomButton width={BUTTON_WIDTH} onClick={closeModal} className={styles.button}>
        閉じる
      </CustomButton>
    </div>
  </Modal>
);

AutoSelectWarningInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  termEndDate: PropTypes.string.isRequired,
  isLabPreview: PropTypes.bool,
};
AutoSelectWarningInfo.defaultProps = {
  isLabPreview: false,
};

export default memo(AutoSelectWarningInfo);
