/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useServiceName } from '../../hooks';
import { BUY_SELL_MAIN, CFD, COUNTRY_TYPE, ETF, FX, QUANTITY_STEP } from '../../constants';
import {
  AMOUNT_PRECISION,
  BUILDER_ORDER_CONFIGURATION_OPTIONS,
  BUILDER_TECH_ETF_NOT_JPY_PRICE_STEP,
  ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN,
  ETF_JPY_PRICE_STEP,
  FX_CFD_STEP,
  INDICATOR1_BUY_TOOLTIP,
  INDICATOR1_SELL_TOOLTIP,
  IS_ALL_SETTLEMENT_TOOLTIP,
  ITEMS_COUNT_STEP,
  RANGE_CFD_SPREAD_STEP,
  RANGE_ETF_JPY_SPREAD_STEP,
  RANGE_ETF_NOT_JPY_SPREAD_STEP,
  RANGE_SPREAD_STEP,
} from '../../constants/builder';
import {
  resetBuilderMultiOrderOptions,
  resetBuilderMultiOrderValidationErrors,
} from '../../redux/actions/builderActions';
import { getAPQuantityStep, getServiceQuantityUnit } from '../index';
import {
  useTechBuilderAddMultiOrderSettings,
  useBuilderCounter,
  useBuilderInstrumentSettings,
  useBuilderTechDefaultSettings,
  useBuilderMultiDisabledValidationOptions,
  useBuilderMultiFollow,
  useTechBuilderOption,
  useBuilderPricePrecision,
  useBuilderValidateAmount,
  useBuilderValidateCounter,
  useBuilderValidateFollow,
  useBuilderValidateItemsCount,
  useBuilderValidateProfitMargin,
  useBuilderValidateStopLoss,
  useGetBuilderServiceId,
  useBuilderMultiOption,
  useBuilderValidateRangeSpread,
  useTechBuilderValidateMultiOptions,
} from './builder';
import { getBuilderDefaultAmount, getPipsLabel, getPipsLabelWithParentheses } from '../../utils';

const getQuantityTooltip = (serviceId) => {
  if (serviceId === FX) {
    // eslint-disable-next-line max-len
    return '各自動売買注文ごとの注文数量を指定します。<br>1＝1万通貨<br>テクニカルビルダーでは、最小0.1（万）＝1,000通貨単位の取引が可能です。<br>※南アランド/円とノルウェー/スウェーデンのみ10,000通貨単位';
  }
  if (serviceId === ETF) {
    return '各自動売買注文ごとの注文数量を指定します。<br>テクニカルビルダーでは、最小1口単位の取引が可能です。<br>※TOPIX連動ETFのみ10口単位';
  }
  // TODO CFD 暫定対応
  if (serviceId === CFD) {
    return '各自動売買注文ごとの注文数量を指定します。<br>テクニカルビルダーでは、最小0.1Lotの取引が可能です。';
  }
  return null;
};

const useTechBuilderMultiFormLogic = (submitCallback, reset) => {
  const dispatch = useDispatch();

  const [initialLoading, setInitialLoading] = useState(true);

  const isChartDataLoading = useSelector((state) => state.builder.chartDataIsLoading);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);
  const simulationDataIsLoading = useSelector((state) => state.builder.simulationDataIsLoading);
  const multiOrderValidationErrors = useSelector((state) => state.builder.multiOrderValidationErrors);

  const serviceId = useGetBuilderServiceId();
  const serviceName = useServiceName(serviceId);
  const isFX = serviceId === FX;
  const isCFD = serviceId === CFD;

  const quantityUnit = getServiceQuantityUnit(serviceId);
  const { allowSellFlg, allowBuyFlg, quantityPrecision } = useBuilderInstrumentSettings();
  const pipsLabel = getPipsLabel(serviceId, instrumentId);
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);

  const defaultSettings = useBuilderTechDefaultSettings();
  const defaultSettingsRef = useRef({});
  useEffect(() => {
    if (defaultSettingsRef?.current) defaultSettingsRef.current = defaultSettings;
  }, [defaultSettings]);

  const { pipsPrecision } = useBuilderPricePrecision();

  const validateRangeSpread = useBuilderValidateRangeSpread();
  const validateItemsCount = useBuilderValidateItemsCount();
  const validateAmount = useBuilderValidateAmount();
  const validateProfitMargin = useBuilderValidateProfitMargin();
  const validateStopLoss = useBuilderValidateStopLoss();
  const validateFollow = useBuilderValidateFollow();
  const validateCounter = useBuilderValidateCounter();

  const [indicator1, setIndicator1] = useTechBuilderOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR1,
  });
  const [indicator2, setIndicator2] = useTechBuilderOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR2,
  });
  const [barType, setBarTypeRaw] = useTechBuilderOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.BAR_TYPE,
  });
  const [isAllSettlement, setIsAllSettlement] = useTechBuilderOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.IS_ALL_SETTLEMENT,
  });
  const [selectedSellBuyId, setSellBuyIdRaw] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.SELECTED_SELL_BUY_ID,
  });
  const [rangeSpread, setRangeSpreadRaw] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD,
    precision: pipsPrecision,
    validate: validateRangeSpread,
  });
  const [itemsCount, setItemsCount] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.ITEMS_COUNT,
    validate: validateItemsCount,
  });
  const [amount, setAmount] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.AMOUNT,
    precision: isFX ? AMOUNT_PRECISION : -Math.log10(quantityPrecision),
    validate: validateAmount,
  });
  const [profitMargin, setProfitMargin] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.PROFIT_MARGIN,
    precision: pipsPrecision,
    validate: validateProfitMargin,
  });
  const [isStopLossSpreadChecked, setCheckStopLossSpread] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD_IS_CHECKED,
  });
  const [stopLossSpread, setStopLossSpread] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD,
    precision: pipsPrecision,
    validate: validateStopLoss,
  });
  const [isFollowValueChecked, setCheckFollowValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE_IS_CHECKED,
  });
  const [followValue, setFollowValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE,
    precision: pipsPrecision,
    validate: validateFollow,
  });
  const [isCounterValueChecked, setCheckCounterValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE_IS_CHECKED,
  });
  const [counterValue, setCounterValue] = useBuilderMultiOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE,
    precision: pipsPrecision,
    validate: validateCounter,
  });
  const [isCounterFixed, setCounterFixed] = useTechBuilderOption({
    fieldName: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_IS_FIXED,
  });

  const addOrderSettings = useTechBuilderAddMultiOrderSettings(submitCallback);
  const validationDisabledOptions = useBuilderMultiDisabledValidationOptions();
  const validateOptions = useTechBuilderValidateMultiOptions();
  const validationOptionsRef = useRef(null);
  validationOptionsRef.current = validateOptions;

  useEffect(() => {
    if (isChartDataLoading || initialLoading) return;

    validateOptions();
  }, [validateOptions, isChartDataLoading, initialLoading]);

  const currentFollowValue = useBuilderMultiFollow(selectedSellBuyId);
  const currentCounterValue = useBuilderCounter(selectedSellBuyId);
  const currentSellBuyOptionsRef = useRef({});
  useEffect(() => {
    if (currentSellBuyOptionsRef?.current) {
      currentSellBuyOptionsRef.current = {
        currentFollowValue,
        currentCounterValue,
      };
    }
  }, [currentFollowValue, currentCounterValue]);

  const setValuesCallbacksRef = useRef({});
  useEffect(() => {
    if (setValuesCallbacksRef?.current) {
      setValuesCallbacksRef.current = {
        setAmount,
        setBarType: setBarTypeRaw,
        setIndicator1,
        setIndicator2,
        setSellBuyId: setSellBuyIdRaw,
        setIsAllSettlement,
        setRangeSpread: setRangeSpreadRaw,
        setItemsCount,
        setProfitMargin,
        setStopLossSpread,
        setCheckFollowValue,
        setFollowValue,
        setCounterValue,
        setCounterFixed,
      };
    }
  }, [
    setAmount,
    setBarTypeRaw,
    setIndicator1,
    setIndicator2,
    setSellBuyIdRaw,
    setIsAllSettlement,
    setRangeSpreadRaw,
    setItemsCount,
    setProfitMargin,
    setStopLossSpread,
    setCheckFollowValue,
    setFollowValue,
    setCounterValue,
    setCounterFixed,
  ]);

  // 不要なrenderを抑制するために足種の更新不要フラグをRefで管理する
  const isBarTypeReset = useRef(true);

  const resetState = useCallback(() => {
    const {
      defaultSelectedSellBuyId,
      defaultBarType,
      defaultIndicator1,
      defaultIndicator2,
      defaultSetIsAllSettlement,
      defaultRangeSpread,
      defaultItemsCount,
      defaultProfitMargin,
      defaultStopLossSpread,
      defaultFollowCheck,
      defaultFollow,
      defaultCounterValue,
      defaultCounterIsFixed,
    } = defaultSettingsRef.current;
    const {
      setAmount: setAmountRef,
      setBarType: setBarTypeRef,
      setIndicator1: setIndicator1Ref,
      setIndicator2: setIndicator2Ref,
      setIsAllSettlement: setIsAllSettlementRef,
      setSellBuyId: setSellBuyIdRef,
      setRangeSpread: setRangeSpreadRef,
      setItemsCount: setItemsCountRef,
      setProfitMargin: setProfitMarginRef,
      setStopLossSpread: setStopLossSpreadRef,
      setCheckFollowValue: setCheckFollowValueRef,
      setFollowValue: setFollowValueRef,
      setCounterValue: setCounterValueRef,
      setCounterFixed: setCounterFixedRef,
    } = setValuesCallbacksRef.current;

    dispatch(resetBuilderMultiOrderOptions());

    const defaultAmount = getBuilderDefaultAmount(serviceId, instrumentId);
    setAmountRef(defaultAmount);
    setSellBuyIdRef(defaultSelectedSellBuyId);
    if (isBarTypeReset.current) {
      setBarTypeRef(defaultBarType);
    }
    setIndicator1Ref(defaultIndicator1);
    setIndicator2Ref(defaultIndicator2);
    setIsAllSettlementRef(defaultSetIsAllSettlement);
    setRangeSpreadRef(defaultRangeSpread);
    setItemsCountRef(defaultItemsCount);
    setProfitMarginRef(defaultProfitMargin);
    setStopLossSpreadRef(defaultStopLossSpread);
    setCheckFollowValueRef(defaultFollowCheck);
    setFollowValueRef(defaultFollow);
    setCounterValueRef(defaultCounterValue);
    setCounterFixedRef(defaultCounterIsFixed);
    dispatch(resetBuilderMultiOrderValidationErrors());

    isBarTypeReset.current = true;

    setTimeout(() => {
      setInitialLoading(false);
    }, 2000);
  }, [dispatch, serviceId, instrumentId]);

  const partialReset = useCallback(() => {
    const { defaultFollow, defaultCounterValue } = defaultSettingsRef.current;
    const { setFollowValue: setFollowValueRef, setCounterValue: setCounterValueRef } = setValuesCallbacksRef.current;

    setFollowValueRef(defaultFollow);
    setCounterValueRef(defaultCounterValue);
    setTimeout(() => {
      validationOptionsRef.current();
    });
  }, []);

  const setSellBuyId = useCallback(
    (value) => {
      setSellBuyIdRaw(value);
      setTimeout(() => partialReset());
    },
    [setSellBuyIdRaw, partialReset],
  );

  const setBarType = useCallback(
    (value) => {
      setBarTypeRaw(value);
      isBarTypeReset.current = false;
    },
    [setBarTypeRaw],
  );

  const setRangeSpread = useCallback(
    (value) => {
      setRangeSpreadRaw(value);
      setTimeout(() => {
        validationOptionsRef.current();
      });
    },
    [setRangeSpreadRaw],
  );

  // チャートデータの読み込みが完了時に全ての項目を初期値に戻す
  useEffect(() => {
    if (!isChartDataLoading && reset) {
      resetState();
    }
  }, [isChartDataLoading, resetState, reset]);

  // TODO CFD FXかそうでないかの判定で問題ないか要確認(また、定数名をどうするか)
  const amountStep = useMemo(() => {
    if (isFX) {
      return getAPQuantityStep(instrumentId);
    }
    if (isCFD) {
      return QUANTITY_STEP.ZERO_POINT_ONE;
    }
    if (instrumentId === ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN) {
      return QUANTITY_STEP.TEN;
    }
    return QUANTITY_STEP.ONE;
  }, [isFX, isCFD, instrumentId]);

  const priceStep = useMemo(() => {
    if (isFX || isCFD) {
      return FX_CFD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return ETF_JPY_PRICE_STEP;
    }
    return BUILDER_TECH_ETF_NOT_JPY_PRICE_STEP;
  }, [isFX, instrumentId, isCFD]);

  const rangeStep = useMemo(() => {
    if (isFX) {
      return RANGE_SPREAD_STEP;
    }
    if (isCFD) {
      return RANGE_CFD_SPREAD_STEP;
    }
    if (instrumentId.includes(COUNTRY_TYPE.JPY)) {
      return RANGE_ETF_JPY_SPREAD_STEP;
    }
    return RANGE_ETF_NOT_JPY_SPREAD_STEP;
  }, [isFX, instrumentId, isCFD]);

  const errorMessages = useMemo(() => {
    if (isChartDataLoading || initialLoading) return [];
    return Object.entries(multiOrderValidationErrors).reduce((messages, [inputName, info]) => {
      if (info.hasValidationError && !validationDisabledOptions.includes(inputName)) {
        messages.push({ inputName, errorMessage: info.errorMessage });
      }
      return messages;
    }, []);
  }, [multiOrderValidationErrors, isChartDataLoading, validationDisabledOptions, initialLoading]);

  const isCounterFollowNotValid = useMemo(
    () => !isFollowValueChecked && !isCounterValueChecked,
    [isFollowValueChecked, isCounterValueChecked],
  );

  const isAddLogicDisabled = useMemo(
    () =>
      isChartDataLoading ||
      !allowOrderEdit ||
      simulationDataIsLoading ||
      errorMessages.length > 0 ||
      isCounterFollowNotValid,
    [isChartDataLoading, allowOrderEdit, simulationDataIsLoading, errorMessages, isCounterFollowNotValid],
  );

  const addTechLogic = useCallback(() => {
    const { hasErrors } = validateOptions();
    if (hasErrors || isAddLogicDisabled) return;
    addOrderSettings();
  }, [addOrderSettings, validateOptions, isAddLogicDisabled]);

  const orderSettingsList = useSelector((state) => state.builder.orderSettingsList);

  return {
    errorMessages,
    reset: {
      handler: resetState,
      isDisabled: isChartDataLoading || initialLoading,
    },
    indicator1: {
      label: 'インジケーター1',
      get: indicator1,
      set: setIndicator1,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip: selectedSellBuyId === BUY_SELL_MAIN.BUY.ID ? INDICATOR1_BUY_TOOLTIP : INDICATOR1_SELL_TOOLTIP,
      image: `tech${serviceName}_indicator1.png`,
    },
    indicator2: {
      label: 'インジケーター2',
      get: indicator2,
      set: setIndicator2,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip:
        'インジケーター1で選択したテクニカル指標と合わせ、もう一つテクニカル指標を追加します。<br>' +
        'インジケーター1と2の条件を満たした際に売買シグナルが発生します。',
      image: `tech${serviceName}_indicator2.png`,
    },
    barType: {
      label: '足種',
      get: barType,
      set: setBarType,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip:
        // eslint-disable-next-line max-len
        'テクニカル指標を設定するチャートの足種を選びます。<br>「4時間足」なら4時間ごと、「8時間足」なら8時間ごと、「日足」なら1日ごとの値動きを表したチャートになります。時間足が長いほど長期的なトレンドを判断しやすい傾向があります。',
      image: `tech${serviceName}_barType.png`,
    },
    isAllSettlement: {
      label: '反対シグナルが出たときに決済するか',
      get: isAllSettlement,
      set: setIsAllSettlement,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip: IS_ALL_SETTLEMENT_TOOLTIP,
      image: `tech${serviceName}_allSettle.png`,
    },
    buySell: {
      label: '売買シグナル',
      get: selectedSellBuyId,
      set: setSellBuyId,
      isSellDisabled: !allowSellFlg,
      isBuyDisabled: !allowBuyFlg,
      tooltip:
        'シグナルによって作成される新規注文の売買方向を選びます。<br>買：上昇トレンドを予想して、買いの自動売買注文を作成します<br>売：下落トレンドを予想して、売りの自動売買注文を作成します',
      image: `multi${serviceName}_buySell.png`,
    },
    rangeSpread: {
      label: `レンジ幅${pipsLabelWithParentheses}`,
      get: rangeSpread,
      set: setRangeSpread,
      step: rangeStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip: isFX // eslint-disable-next-line max-len
        ? '選択したテクニカル指標に基づきシグナルが発信されると、シグナルが発生した時点の終値を挟んで、レンジ幅の範囲内に自動売買注文が作成されます。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '選択したテクニカル指標に基づきシグナルが発信されると、シグナルが発生した時点の終値を挟んで、レンジ幅の範囲内に自動売買注文が作成されます。',
      image: `tech${serviceName}_rangeSpread.png`,
    },
    itemsCount: {
      label: '本数',
      get: itemsCount,
      set: setItemsCount,
      isDisabled: isChartDataLoading,
      step: ITEMS_COUNT_STEP,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.ITEMS_COUNT,
      tooltip: isFX // eslint-disable-next-line max-len
        ? '指定したレンジ幅の中に何本の注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔（pips）＝レンジ幅÷本数<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '指定したレンジ幅の中に何本の注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔＝レンジ幅÷本数',
      image: `tech${serviceName}_items_count.png`,
    },
    quantity: {
      label: `数量(${quantityUnit})`,
      get: amount,
      set: setAmount,
      isDisabled: isChartDataLoading,
      step: amountStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.AMOUNT,
      tooltip: getQuantityTooltip(serviceId),
      image: `multi${serviceName}_quantity.png`,
    },
    profitMargin: {
      label: `利確幅${pipsLabelWithParentheses}`,
      get: profitMargin,
      set: setProfitMargin,
      isDisabled: isChartDataLoading,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.PROFIT_MARGIN,
      tooltip: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅（pips）で指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅で指定します。',
      image: `multi${serviceName}_profitMargin.png`,
    },
    stopLossCheckbox: {
      label: `損切幅${pipsLabelWithParentheses}`,
      get: isStopLossSpreadChecked,
      set: setCheckStopLossSpread,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅（pips）で指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅で指定します。',
      image: `multi${serviceName}_stopLoss.png`,
    },
    stopLossInput: {
      get: stopLossSpread,
      set: setStopLossSpread,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD,
      min: null,
      isDisabled: !isStopLossSpreadChecked || isChartDataLoading,
    },
    followCheckbox: {
      label: `フォロー値${pipsLabelWithParentheses}`,
      get: isFollowValueChecked,
      set: setCheckFollowValue,
      isDisabled: isChartDataLoading || initialLoading,
      tooltip: isFX // eslint-disable-next-line max-len
        ? 'フォロー値は、買いの場合は決済価格からどのくらい上がったら、次新たに買うかを値幅（pips）で設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい下がったらまた売るかを設定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : 'フォロー値は、買いの場合は決済価格からどのくらい上がったら、次新たに買うかを値幅で設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい下がったらまた売るかを設定します。',
      image: `multi${serviceName}_follow.png`,
    },
    followInput: {
      get: followValue,
      set: setFollowValue,
      isDisabled: !isFollowValueChecked || isChartDataLoading,
      step: priceStep,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE,
      min: null,
    },
    counterCheckbox: {
      label: `カウンター値${pipsLabelWithParentheses}`,
      get: isCounterValueChecked,
      set: setCheckCounterValue,
      isDisabled: isChartDataLoading,
      pipsLabel,
      tooltip: isFX // eslint-disable-next-line max-len
        ? 'カウンター値は、買いの場合は決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい上がったらまた売るかを設定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）' // eslint-disable-next-line max-len
        : 'カウンター値は、買いの場合は決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい上がったらまた売るかを設定します。',
      image: `multi${serviceName}_counter.png`,
    },
    counterInput: {
      get: counterValue,
      set: setCounterValue,
      isDisabled: !isCounterValueChecked || isChartDataLoading,
      step: priceStep,
      min: null,
      name: BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE,
    },
    counterFixedCheckbox: {
      get: isCounterFixed,
      set: setCounterFixed,
      label: 'カウンター固定',
      isDisabled: !isCounterValueChecked || isChartDataLoading || initialLoading,
      tooltip: isFX // eslint-disable-next-line max-len
        ? 'ONの場合は、カウンター値が価格固定となり、決済後、各注文はそれぞれ決まった価格で新規発注を繰り返すことができます。<br>OFFの場合は、決済価格からカウンター値の値幅分（pips）離れたところに新規発注します。' // eslint-disable-next-line max-len
        : 'ONの場合は、カウンター値が価格固定となり、決済後、各注文はそれぞれ決まった価格で新規発注を繰り返すことができます。<br>OFFの場合は、決済価格からカウンター値の値幅分離れたところに新規発注します。',
      image: `multi${serviceName}_counter_fix.png`,
    },
    invalidCounter: {
      condition: isCounterFollowNotValid,
      message: 'フォロー値かカウンター値のいずれかをご設定ください',
    },
    submit: {
      label: orderSettingsList.length ? 'ロジック変更' : 'ロジック追加',
      handler: addTechLogic,
      isDisabled: isAddLogicDisabled,
    },
  };
};

export default useTechBuilderMultiFormLogic;
