import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ValueWithPreservePrecision from '../ValueWithPreservePrecision';

const InstrumentPreservePrecisionTableCell = ({ cell }) => {
  const { value } = cell;

  return (
    <ValueWithPreservePrecision
      value={Number.isFinite(Number(value)) && value !== null ? Number(value) : null}
      instrumentId={cell.row.original.instrumentId}
    />
  );
};

InstrumentPreservePrecisionTableCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    row: PropTypes.shape({
      original: PropTypes.shape({
        instrumentId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(InstrumentPreservePrecisionTableCell);
