import { call, put, select } from 'redux-saga/effects';
import { getOrderInfo, getPositionsOrderById } from '../../../api/manualTradeApi';
import {
  getOrderInfoSuccess,
  orderInfoRequestStartLoading,
  orderInfoRequestStopLoading,
} from '../../actions/manualTradeActions';
import { errorHandler } from '../errorSaga';

function* getOrderInfoRequestHandler(action) {
  try {
    yield put(orderInfoRequestStartLoading());

    const serviceId = yield select((state) => state.auth.serviceId);

    const {
      payload: { orderId, callback },
    } = action;

    const { data: array } = yield call(getOrderInfo, { orderId, serviceId });

    let positionInfo = {};

    if (array?.[0]?.closePositionId) {
      const { data } = yield call(getPositionsOrderById, { positionId: array[0].closePositionId, serviceId });
      positionInfo = { ...data[0] };
    }

    yield put(getOrderInfoSuccess({ array, positionInfo }));

    if (callback) callback();
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(orderInfoRequestStopLoading());
  }
}

export default getOrderInfoRequestHandler;
