import Decimal from 'decimal.js';
import {
  PAIRED_YEN_PIP_MULTIPLIER,
  NOT_PAIRED_YEN_PIP_MULTIPLIER,
  COUNTRY_TYPE,
  FX,
  ETF,
  CFD,
  TRADE_METHODS,
} from '../../constants/index';
import { roundToPrecision } from '../index';
import { decimalRounding } from '../builder';

export const floor = (value, digits) => {
  return roundToPrecision(value, Decimal.pow(10, digits).toNumber());
};

export const ceil = (value, digits) => {
  return Decimal.div(Math.ceil(Decimal.mul(value, Decimal.pow(10, digits))), Decimal.pow(10, digits)).toNumber();
};

export const getBaseQuantity = (serviceId, instrumentId, instrumentSetting) => {
  if (serviceId === FX) {
    return instrumentId === 'TRY_AP/JPY' || instrumentId === 'ZAR_AP/JPY' || instrumentId === 'NOK_AP/SEK'
      ? 10000
      : 1000;
  }
  if (serviceId === ETF) {
    return instrumentId === '1306.TKS/JPY' ? 10 : 1;
  }
  if (serviceId === CFD) {
    const quantityPrecision = instrumentSetting?.settings?.[TRADE_METHODS.AP.ID]?.quantityPrecision;
    return quantityPrecision ?? 0.1;
  }
  return 0;
};

export const getSignificantDigits = (serviceId, instrumentId, pricePrecision) => {
  if (pricePrecision) {
    return Math.log10(pricePrecision) * -1;
  }

  if (serviceId === FX) {
    const pattern = '/JPY';
    return instrumentId.lastIndexOf(pattern) + pattern.length === instrumentId.length &&
      pattern.length <= instrumentId.length
      ? 3
      : 5;
  }
  const pattern = '/JPY';
  return instrumentId.lastIndexOf(pattern) + pattern.length === instrumentId.length &&
    pattern.length <= instrumentId.length
    ? 0
    : 2;
};

export const pipsToPrice = (pips, instrumentId) => {
  const pipConversion = instrumentId.endsWith(COUNTRY_TYPE.JPY)
    ? PAIRED_YEN_PIP_MULTIPLIER
    : NOT_PAIRED_YEN_PIP_MULTIPLIER;
  return decimalRounding(Decimal.mul(pips, pipConversion));
};

export const priceToPips = (price, instrumentId, serviceId) => {
  if (serviceId && serviceId !== FX) {
    return price.toNumber();
  }
  const pipConversion = instrumentId.endsWith(COUNTRY_TYPE.JPY)
    ? PAIRED_YEN_PIP_MULTIPLIER
    : NOT_PAIRED_YEN_PIP_MULTIPLIER;
  return decimalRounding(Decimal.div(price, pipConversion));
};

export const convertFromDispQuiantity = (quantity) => {
  return Decimal.mul(quantity, 10000);
};

export const isJPY = (instrumentId) => {
  return instrumentId.lastIndexOf('/JPY') + '/JPY'.length === instrumentId.length;
};
