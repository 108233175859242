import { memo } from 'react';
import { useSelector } from 'react-redux';
import { formatNumberToDisplayedString, fillOrPreserveToPrecision } from 'shared-modules/services';
import PropTypes from 'prop-types';

const ValueWithPreservePrecision = ({ value, instrumentId }) => {
  const pricePrecision = useSelector((state) => state.settings.instrumentList[instrumentId]?.pricePrecision);

  if (!value) return '-';

  return formatNumberToDisplayedString({
    value: fillOrPreserveToPrecision(value, pricePrecision || 1),
    withoutPlus: true,
    withYenIcon: false,
  });
};

ValueWithPreservePrecision.propTypes = {
  value: PropTypes.number,
  instrumentId: PropTypes.string.isRequired,
};

ValueWithPreservePrecision.defaultProps = {
  value: 0,
};

export default memo(ValueWithPreservePrecision);
