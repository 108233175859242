// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { publishedLabSimulationSelector } from '../redux/labs';
import { normalizeLabStrategy, normalizeSelectionStrategy } from '../services/select';
import { useSelectedStrategyGroup } from './useSelectedStrategyGroup';
import { useSelectedShareItem } from './useSelectedShareItem';

export const useSelectedStrategyInfoList = () => {
  const selectedShareItem = useSelectedShareItem();
  const autoSelectTermId = useSelector((state) => state.autoSelect.selectedTermId);
  const labTermId = useSelector((state) => state.labs.publishedLabs.selectedTermId);
  // シミュレーション期間取得
  const selectedTermId = selectedShareItem ? labTermId : autoSelectTermId;
  const [selectedStrategyGroup] = useSelectedStrategyGroup();
  const { [selectedTermId]: { strategyList: strategyListSelection } = {} } = useSelector(
    (state) => state.autoSelect.selectionModalInfo,
  );
  const { result } = useSelector(publishedLabSimulationSelector);
  const { strategyList: strategyListLab } = result || {};
  const { serviceId } = selectedStrategyGroup || {};
  return useMemo(() => {
    if (selectedShareItem) {
      return [(strategyListLab ?? []).map((strategy) => normalizeLabStrategy(strategy, serviceId)), selectedTermId];
    }
    return [(strategyListSelection ?? []).map((strategy) => normalizeSelectionStrategy(strategy)), selectedTermId];
  }, [selectedShareItem, serviceId, strategyListSelection, strategyListLab, selectedTermId]);
};
