import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useCheckOnPositionIsBeingDeleted, useGetStillDeletingPositions } from 'shared-modules/services/hooks';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { ORDER_METHOD_MAIN } from 'shared-modules/constants';
import { useIsMarkupTarget } from 'shared-modules/hooks/markup';
import {
  openManualTradeTableCloseOrder,
  openConfirmationModal,
  deletePositionsOrderById,
  saveDeletingPositions,
} from '../../../../redux/actions';
import styles from './deleteButton.module.scss';
import CustomButton from '../../../CustomButton';

const DeleteButton = ({ original }) => {
  const { positionId, side, instrumentId, quantity, settlingQuantity } = original;
  const dispatch = useDispatch();

  const checkOnPositionIsBeingDeleted = useCheckOnPositionIsBeingDeleted();
  const getStillDeletingPositions = useGetStillDeletingPositions();

  const isMarkupTarget = useIsMarkupTarget({
    instrumentId,
    orderType: ORDER_METHOD_MAIN.MARKET.ID,
    orderAmount: quantity,
  });

  const deletePosition = useCallback(() => {
    dispatch(
      deletePositionsOrderById({
        orderId: positionId,
        side,
        instrumentId,
        callback: () => {
          const stillDeletingPositions = getStillDeletingPositions();

          // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
          const positions = [{ positionId, deletionStartedAt: Date.now() }, ...stillDeletingPositions];

          dispatch(saveDeletingPositions({ positions }));
        },
      }),
    );
  }, [dispatch, getStillDeletingPositions, instrumentId, positionId, side]);

  const openQuickCloseModal = useCallback(() => {
    checkOnPositionIsBeingDeleted({
      positionId,
      successCallback: () => {
        dispatch(
          openConfirmationModal({
            title: 'クイック注文決済',
            bodyText: (
              <>
                <p>注文中の決済注文を取り消し、全建玉数量の成行決済注文を発注いたします。よろしいでしょうか？</p>
                {isMarkupTarget && (
                  <div>
                    決済建玉の数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは
                    <a
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textDecorationStyle: 'solid',
                        textDecorationThickness: '0%',
                      }}
                      href={MARKUPS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </a>
                  </div>
                )}
              </>
            ),
            callback: deletePosition,
            buttonBackText: '戻る',
            buttonNextText: '決済',
            isOverlap: true,
          }),
        );
      },
    });
  }, [checkOnPositionIsBeingDeleted, deletePosition, dispatch, positionId, isMarkupTarget]);

  const isDisabled = useMemo(() => quantity - settlingQuantity === 0, [quantity, settlingQuantity]);

  return (
    <div className={styles.buttonWrapper}>
      <CustomButton
        isSmall
        className={styles.marginRight}
        // eslint-disable-next-line
        isDisabled={isDisabled}
        onClick={() => dispatch(openManualTradeTableCloseOrder({ positionInfo: original }))}
      >
        決済
      </CustomButton>
      <CustomButton isSmall onClick={openQuickCloseModal}>
        クイック
      </CustomButton>
    </div>
  );
};

DeleteButton.propTypes = {
  original: PropTypes.shape({
    instrumentId: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    positionId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    settlingQuantity: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeleteButton;
