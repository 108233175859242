import classNames from 'classnames';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUY_SELL_CHART_VALUE,
  BUY_SELL_MAIN,
  CHART_TYPES_MAIN,
  FX,
  PRICE_1_NAME,
  PRICE_2_NAME,
} from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { KEY_FOR_CHART_INDICATORS } from 'shared-modules/constants/chart';
import { makeMaintenanceQueryString } from 'shared-modules/utils';
import { useAccountInfo } from 'shared-modules/hooks';
import { getDefaultValuesFromLocalStorage, saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { decimalRounding, getColorBySide } from 'shared-modules/services/builder';
import { useSelectionsTableData } from 'shared-modules/services/hooks/autoSelectDetails';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import { v4 as uuid } from 'uuid';
import { removeSuffix, useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { changeChartResolutionSelect } from 'shared-modules/redux/actions/chartActions';
import { Spin } from '../../../../../../components';
import { overrides, studiesAccess, studiesOverrides } from '../../../../../../components/Chart/Chart';
import datafeed from '../../../../../../components/Chart/datafeed';
import indicators from '../../../../../../components/Chart/indicators';
import { OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART, POPUP_MESSAGES } from '../../../../../../constants';
import { changeChartSide } from '../../../../../../redux/actions';
import { setTutorialIsReadyAutoSelect } from '../../../../../../redux/actions/tutorialActions';
import { useAddSubWindow } from '../../../../../../hooks';
import styles from './auroSelectDetailChart.module.scss';
import { tutorialTradingView } from '../../../../../../constants/tutorial/classNames';

const saveIndicatorDelay = 300;

function resolveCall(deferredCallRef) {
  if (deferredCallRef.current) {
    deferredCallRef.current();
    deferredCallRef.current = null; // eslint-disable-line no-param-reassign
  }
}

function callOnChartReady(widgetRef, deferredCallRef, callback) {
  if (widgetRef.current?.onChartReady) {
    try {
      widgetRef.current.onChartReady(callback);
    } catch (error) {
      // do nothing
    }
    return;
  }
  // for full screen page, when requested not completed yet
  deferredCallRef.current = callback; // eslint-disable-line no-param-reassign
}

const removeAllLines = (linesRef) => {
  Object.values(linesRef.current).forEach((line) => line.remove());
  linesRef.current = {}; // eslint-disable-line
};

const AutoSelectDetailChart = ({
  isFullScreen,
  selectedInstrumentId,
  selectedStrategyId,
  resolution: resolutionOuter,
  chartType,
  isMobile,
  serviceId,
  disableHeaderWidget,
  withoutIndicators,
  forceChartType,
  autoSelectOrderPositions,
  selectionId,
  selectionVersion,
  termId,
  onChangeLoading,
}) => {
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const dispatch = useDispatch();
  const addSubWindow = useAddSubWindow();
  const drawLinesRef = useRef(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onChangeLoading?.(loading);
  }, [loading, onChangeLoading]);

  const tvWidgetRef = useRef({});
  const containerId = useMemo(() => uuid(), []);

  const resolutionInner = useSelector((state) => state.chart.resolutionSelect);
  const resolution = useMemo(() => resolutionOuter || resolutionInner, [resolutionOuter, resolutionInner]);

  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const accountInfo = useAccountInfo();

  const fullScreenPopupRef = useRef({});
  const onChartExpandClick = useCallback(() => {
    const maintenanceQueryString = makeMaintenanceQueryString(accountInfo);
    let url = `/${OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART}?serviceId=${serviceId}&&${maintenanceQueryString}`;
    if (selectedInstrumentId) url += `&&selectedInstrumentId=${selectedInstrumentId}`;
    if (selectedStrategyId) url += `&&selectedStrategyId=${selectedStrategyId}`;
    if (autoSelectOrderPositions) {
      url += `&&autoSelectOrderPositions=${autoSelectOrderPositions}`;
      url += `&&termId=${termId}`;
      url += `&&selectionId=${selectionId}`;
      url += `&&selectionVersion=${selectionVersion}`;
    }

    const params = 'status=no,location=0,toolbar=no,menubar=no,width=800,height=600,left=150,top=150';
    fullScreenPopupRef.current = window.open(encodeURI(url), OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART, params);
    addSubWindow(fullScreenPopupRef.current);
    return selectedInstrumentId;
  }, [
    serviceId,
    selectedInstrumentId,
    selectedStrategyId,
    autoSelectOrderPositions,
    termId,
    selectionId,
    selectionVersion,
    accountInfo,
    addSubWindow,
  ]);

  const usePriceForSide = () => {
    const price = useSelector((state) => state.currencies.rates[selectedInstrumentId]);
    const initialPrecision = instrumentList[selectedInstrumentId]?.pricePrecision ?? 0.01;
    const pricePrecision = useMemo(() => {
      const logResult = Math.log10(initialPrecision);
      return (logResult === 0 ? 0 : -logResult) ?? 0;
    }, [initialPrecision]);
    const { ask, bid } = price ?? {};
    return useMemo(
      () => ({
        basePriceAsk: decimalRounding(new Decimal(ask ?? 0), pricePrecision),
        basePriceBid: decimalRounding(new Decimal(bid ?? 0), pricePrecision),
      }),
      [ask, bid, pricePrecision],
    );
  };
  const instrumentOptions = useApOrManualInstrumentOptions(false);
  const selectedSide = useSelector((state) => state.manualTrade.chartSide);
  const createAskBidToggle = (title) => <div className="askbid-toggle">{title}</div>;
  const { basePriceAsk, basePriceBid } = usePriceForSide();
  const basePrice = useMemo(
    () => (Number(selectedSide) === BUY_SELL_MAIN.BUY.CHART_ID ? basePriceAsk : basePriceBid),
    [selectedSide, basePriceAsk, basePriceBid],
  );

  const otherWindowChartOpenButtonWidget = useRef({});

  const createOtherWindowChartOpenButton = useCallback((tempTvWidgetRef, otherWindowChartOpenButtonRef, onClick) => {
    const buttonRef = otherWindowChartOpenButtonRef;
    const button = tempTvWidgetRef.current?.createButton && tempTvWidgetRef.current.createButton({ align: 'right' });
    if (!button) {
      return;
    }
    buttonRef.current = button;
    button.classList.add('button', 'button-square');
    button.setAttribute('id', 'open-other-window');
    button.setAttribute('title', '別画面で開く');
    button.addEventListener('click', onClick);
    button.innerHTML = `<svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#868993"
    height="22" 
    width="22"
    viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d='M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3
        9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3
        2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z'
      />
    </svg>`;
  }, []);

  const modifyOtherWindowChartOpenButton = useCallback((button, onClick) => {
    button.removeEventListener('click', onClick);
    button.addEventListener('click', onClick);
  }, []);

  const autoSelectOrderLinesRef = useRef({});
  const { autoTradeSettingsData } = useSelectionsTableData(termId);
  const [finishedOnChartReady, setFinishedOnChartReady] = useState(false);
  const finishedOnChartReadyRef = useRef(finishedOnChartReady);
  const prevFinishedOnChartReadyRef = useRef(finishedOnChartReady);
  const [changingSymbol, setChangingSymbol] = useState(false);
  const changingSymbolRef = useRef(changingSymbol);
  const prevChangingSymbolRef = useRef(changingSymbol);
  const prevSymbolRef = useRef(null);
  const [changingResolution, setChangingResolution] = useState(false);
  const changingResolutionRef = useRef(changingResolution);
  const prevChangingResolutionRef = useRef(changingResolution);
  const prevResolutionRef = useRef(null);
  const dropdownRef = useRef(null);

  const shortName = useMemo(
    () => instrumentList?.[selectedInstrumentId]?.shortName,
    [instrumentList, selectedInstrumentId],
  );

  const symbol = useMemo(() => {
    return `${serviceId === FX ? removeSuffix(selectedInstrumentId) : shortName}(${
      BUY_SELL_CHART_VALUE[selectedSide]
    })`;
  }, [serviceId, selectedInstrumentId, shortName, selectedSide]);

  const renderLines = useCallback(() => {
    if (changingSymbolRef.current || changingSymbol) {
      // symbol 変更中の場合は symbol 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    if (changingResolutionRef.current || changingResolution) {
      // resolution 変更中の場合は resolution 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    removeAllLines(autoSelectOrderLinesRef);
    if (!autoSelectOrderPositions) {
      return;
    }
    autoTradeSettingsData
      .filter((i) => i.instrumentId === selectedInstrumentId && i.strategyId === selectedStrategyId)
      .forEach((i) => {
        const orderLineEntryprice1 = autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_1_NAME}`];
        if (!orderLineEntryprice1) {
          autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_1_NAME}`] = tvWidgetRef.current
            .activeChart()
            .createOrderLine()
            .setText('')
            .setQuantity('')
            .setLineColor(getColorBySide(Number(i.side)))
            .setPrice(i.entryPrice1);
        } else {
          autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_1_NAME}`].setPrice(i.entryPrice1);
        }

        if (i?.entryPrice2) {
          const orderLineEntryprice2 = autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_2_NAME}`];
          if (!orderLineEntryprice2) {
            autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_2_NAME}`] = tvWidgetRef.current
              .activeChart()
              .createOrderLine()
              .setText('')
              .setQuantity('')
              .setLineColor(getColorBySide(Number(i.side)))
              .setPrice(i.entryPrice2);
          } else {
            autoSelectOrderLinesRef.current[`${i.strategyId}-${i.itemId}-${PRICE_2_NAME}`].setPrice(i.entryPrice2);
          }
        }
      });
  }, [
    changingSymbol,
    changingResolution,
    autoSelectOrderPositions,
    autoTradeSettingsData,
    selectedInstrumentId,
    selectedStrategyId,
  ]);

  const toggleChartSide = useCallback(
    (value) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ side: value }));
      }
      dispatch(changeChartSide({ id: value }));
    },
    [dispatch],
  );
  const handleDropdownItemSelect = useCallback(
    (title, value) => {
      dropdownRef.current.applyOptions({
        title,
      });
      toggleChartSide(value);
    },
    [toggleChartSide],
  );
  const createDropdownItem = useCallback(
    (title, value) => ({
      title,
      onSelect: () => {
        handleDropdownItemSelect(title, value);
      },
    }),
    [handleDropdownItemSelect],
  );
  const dropdownOptionsRef = useRef(null);
  const dropdownOptions = useMemo(
    () => ({
      title: createAskBidToggle(Number(selectedSide) ? 'ASK' : 'BID'),
      items: [createDropdownItem(createAskBidToggle('BID'), 0), createDropdownItem(createAskBidToggle('ASK'), 1)],
    }),
    [createDropdownItem, selectedSide],
  );

  useEffect(() => {
    dropdownRef.current?.applyOptions(dropdownOptions);
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  // チャート準備完了時に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (finishedOnChartReady !== prevFinishedOnChartReadyRef.current) {
      prevFinishedOnChartReadyRef.current = finishedOnChartReady;
      if (finishedOnChartReady) {
        renderLines();
      }
    }
  }, [finishedOnChartReady, renderLines]);

  // シンボル変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingSymbol !== prevChangingSymbolRef.current) {
      prevChangingSymbolRef.current = changingSymbol;
      if (!changingSymbol) {
        renderLines();
      }
    }
  }, [changingSymbol, renderLines]);

  useEffect(() => {
    if (
      ALL_SERVICES.some((service) => !instrumentOptions[service].length) ||
      !selectedInstrumentId ||
      !serviceId ||
      tvWidgetRef.current.headerReady
    ) {
      return;
    }

    const widgetOptions = {
      symbol,
      datafeed: datafeed({ instrumentOptions, isMobile, serviceId, instrumentList }),
      interval: resolution,
      container: containerId,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle2.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        isFullScreen && 'header_settings',
        'items_favoriting',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        'header_chart_type',
        'timeframes_toolbar',
        'study_dialog_search_control',
        'create_volume_indicator_by_default',
        'header_symbol_search',
        'symbol_search_hot_key',
        disableHeaderWidget && 'header_widget',
        'left_toolbar',
        isMobile && 'property_pages',
        isMobile && 'context_menus',
      ],
      enabled_features: [(!isFullScreen || disableHeaderWidget) && 'hide_left_toolbar_by_default', 'chart_zoom'],
      autosize: true,
      theme: 'Dark',
      studies_access: studiesAccess,
      custom_indicators_getter(PineJS) {
        return Promise.resolve(indicators(PineJS));
      },
      drawings_access: {
        type: 'white',
        tools: [
          { name: '十字' },
          { name: '矢印' },
          { name: 'トレンドライン' },
          { name: '水平線' },
          { name: '垂直線' },
          { name: '平行チャネル' },
          { name: 'フィボナッチ・リトレースメント' },
        ],
      },
      studies_overrides: studiesOverrides,
      overrides,
      settings_overrides: overrides,
    };
    finishedOnChartReadyRef.current = false;
    setFinishedOnChartReady(false);
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line

    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.headerReady().then(() => {
        const addDropDown = async () => {
          dropdownRef.current = await tvWidgetRef.current.createDropdown(dropdownOptionsRef.current);
        };
        addDropDown();
      });
    });
    if (!isMobile && !isFullScreen && !disableHeaderWidget) {
      tvWidgetRef.current.headerReady().then(() => {
        createOtherWindowChartOpenButton(tvWidgetRef, otherWindowChartOpenButtonWidget, onChartExpandClick);
      });
    }

    if (!tvWidgetRef.current?.onChartReady) return;
    tvWidgetRef.current.onChartReady(() => {
      finishedOnChartReadyRef.current = true;
      // これをトリガーとして chart object の描画が行われる
      setFinishedOnChartReady(true);
      if (tutorialMode) {
        dispatch(setTutorialIsReadyAutoSelect({ isReadyAutoSelect: true }));
      }
    });
    tvWidgetRef.current.onChartReady(() => {
      if (!withoutIndicators) {
        const savedIndicators = JSON.parse(
          getDefaultValuesFromLocalStorage({ key: KEY_FOR_CHART_INDICATORS, defaultValue: null }),
        );
        if (savedIndicators) {
          tvWidgetRef.current.activeChart().applyStudyTemplate(savedIndicators);
        }

        const updateIndicatorsSettings = () =>
          setTimeout(() => {
            if (!tvWidgetRef.current?.activeChart) return;
            const newIndicators = tvWidgetRef.current.activeChart().createStudyTemplate({});
            saveDefaultValuesFromLocalStorage({ key: KEY_FOR_CHART_INDICATORS, value: JSON.stringify(newIndicators) });
          }, saveIndicatorDelay);

        tvWidgetRef.current.subscribe('study', updateIndicatorsSettings);
        tvWidgetRef.current.subscribe('study_event', updateIndicatorsSettings);
        tvWidgetRef.current.subscribe('study_properties_changed', updateIndicatorsSettings);
      }

      tvWidgetRef.current.subscribe('time_interval', (e) => {
        const newResolution = e.label;
        if (newResolution !== prevResolutionRef.current) {
          changingResolutionRef.current = true;
          setChangingResolution(true);
        }
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ resolutionSelect: newResolution }));
        }
        dispatch(changeChartResolutionSelect({ resolutionSelect: newResolution }));
      });

      resolveCall(drawLinesRef);

      setLoading(false);
    });
  }, [
    withoutIndicators,
    containerId,
    isFullScreen,
    isMobile,
    createOtherWindowChartOpenButton,
    onChartExpandClick,
    instrumentOptions,
    selectedInstrumentId,
    instrumentList,
    selectedSide,
    resolution,
    dispatch,
    serviceId,
    disableHeaderWidget,
    autoSelectOrderPositions,
    symbol,
    tutorialMode,
  ]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current && tvWidgetRef.current.remove) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  useEffect(() => {
    if (!isMobile && !isFullScreen && !disableHeaderWidget) {
      tvWidgetRef.current.headerReady().then(() => {
        modifyOtherWindowChartOpenButton(otherWindowChartOpenButtonWidget.current, onChartExpandClick);
      });
    }
  }, [
    selectedInstrumentId,
    isMobile,
    isFullScreen,
    disableHeaderWidget,
    onChartExpandClick,
    modifyOtherWindowChartOpenButton,
  ]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    const changeChartProps = () => {
      if (isMobile || forceChartType) {
        tvWidgetRef.current.chart().setChartType(chartType);
      }

      const callbacks = [];
      if (symbol !== prevSymbolRef.current) {
        changingSymbolRef.current = true;
        setChangingSymbol(true); // symbol 変更開始
        callbacks.push(() => {
          prevSymbolRef.current = symbol;
          changingSymbolRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingSymbol(false); // symbol 変更終了
        });
      }
      if (resolution !== prevResolutionRef.current) {
        callbacks.push(() => {
          prevResolutionRef.current = resolution;
          changingResolutionRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingResolution(false); // resolution 変更終了
        });
      }

      tvWidgetRef.current.setSymbol(symbol, resolution, () => callbacks.forEach((cb) => cb()));
    };
    if (finishedOnChartReadyRef.current) {
      changeChartProps();
    } else {
      tvWidgetRef.current.onChartReady(changeChartProps);
    }
  }, [resolution, chartType, isMobile, forceChartType, symbol]);

  const fullScreenPopupEventListener = useCallback(
    (e) => {
      if (isMobile || !e.isTrusted || !e.source) {
        return;
      }
      try {
        if (e.source.name !== OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART) {
          return;
        }
      } catch {
        // 画面遷移後に実行されてしまった場合、DOMException が発生する。
        // 破棄対象のイベントハンドラなので、後続の処理を行う必要はない。
        return;
      }
      switch (e.data.message) {
        case POPUP_MESSAGES.GET_CHART_TYPE_REQUEST: {
          if (fullScreenPopupRef.current && fullScreenPopupRef.current.postMessage) {
            fullScreenPopupRef.current.postMessage(
              {
                message: POPUP_MESSAGES.GET_CHART_TYPE_SUCCESS,
                payload: {
                  selectedInstrumentId,
                  selectedSide,
                  serviceId,
                },
              },
              '*',
            );
          }
          break;
        }
        default: {
          // empty
        }
      }
    },
    [selectedInstrumentId, selectedSide, serviceId, isMobile],
  );

  useEffect(() => {
    window.addEventListener('message', fullScreenPopupEventListener);
    return () => {
      window.removeEventListener('message', fullScreenPopupEventListener);
    };
  }, [fullScreenPopupEventListener]);

  // チャートオブジェクトに関連するプロパティの変更をトリガーに描画を試みる
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    if (finishedOnChartReadyRef.current) {
      renderLines();
    } else {
      tvWidgetRef.current.onChartReady(() => renderLines());
    }
  }, [renderLines, selectedSide]);
  // チャート準備完了時に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (finishedOnChartReady !== prevFinishedOnChartReadyRef.current) {
      prevFinishedOnChartReadyRef.current = finishedOnChartReady;
      if (finishedOnChartReady) {
        renderLines();
      }
    }
  }, [finishedOnChartReady, renderLines]);

  // シンボル変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingSymbol !== prevChangingSymbolRef.current) {
      prevChangingSymbolRef.current = changingSymbol;
      if (!changingSymbol) {
        renderLines();
      }
    }
  }, [changingSymbol, renderLines]);

  // 足種変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingResolution !== prevChangingResolutionRef.current) {
      prevChangingResolutionRef.current = changingResolution;
      if (!changingResolution) {
        renderLines();
      }
    }
  }, [changingResolution, renderLines]);

  const prevPriceRangeRef = useRef({ max: 0, min: 0, selectedInstrumentId: null, selectedStrategyId: null });
  const prevResetScalesCbRef = useRef(null);

  useEffect(() => {
    if (isMobile && basePrice && autoTradeSettingsData.length && selectedInstrumentId && selectedStrategyId) {
      const allPriceList = autoTradeSettingsData
        .filter((i) => i.instrumentId === selectedInstrumentId && i.strategyId === selectedStrategyId)
        .reduce((acc, item) => {
          if (item?.entryPrice1) acc.push(Number(item.entryPrice1));
          if (item?.entryPrice2) acc.push(Number(item.entryPrice2));
          return acc;
        }, []);

      setTimeout(() => {
        callOnChartReady(tvWidgetRef, drawLinesRef, () => {
          // margin top&bottom 1%
          const priceRange = {
            from: Number(Number.parseFloat(Math.min(...allPriceList, basePrice) * 0.99).toFixed(4)),
            to: Number(Number.parseFloat(Math.max(...allPriceList, basePrice) * 1.01).toFixed(4)),
            selectedInstrumentId,
            selectedStrategyId,
          };
          // VisiblePriceRange reset only MaxPrice or MinPrice or selectedInstrumentId or selectedStrategyId has changed
          if (
            priceRange.from <= prevPriceRangeRef.current.from &&
            priceRange.to <= prevPriceRangeRef.current.to &&
            selectedInstrumentId === prevPriceRangeRef.current.selectedInstrumentId &&
            selectedStrategyId === prevPriceRangeRef.current.selectedStrategyId
          ) {
            return;
          }
          prevPriceRangeRef.current = priceRange;

          if (prevResetScalesCbRef.current)
            tvWidgetRef.current.unsubscribe('reset_scales', prevResetScalesCbRef.current);

          const resetScalesCb = () => {
            setTimeout(() => {
              tvWidgetRef.current
                .activeChart()
                .getPanes()[0]
                .getMainSourcePriceScale()
                .setVisiblePriceRange(prevPriceRangeRef.current);
            }, 50);
          };
          tvWidgetRef.current.subscribe('reset_scales', resetScalesCb);
          prevResetScalesCbRef.current = resetScalesCb;
        });
      }, 100);
    }
  }, [isMobile, basePrice, autoTradeSettingsData, selectedInstrumentId, selectedStrategyId]);

  return (
    <div className={classNames(styles.wrapper, { [styles.loading]: loading })}>
      {loading && <Spin className={styles.loader} />}
      <div className={classNames(styles.chartAreaWrapper, { [styles.displayNone]: loading })}>
        <div id={containerId} className={classNames(styles.chartContainer, tutorialTradingView)}>
          Chart
        </div>
      </div>
    </div>
  );
};
AutoSelectDetailChart.propTypes = {
  isFullScreen: PropTypes.bool,
  selectedInstrumentId: PropTypes.string.isRequired,
  selectedStrategyId: PropTypes.number.isRequired,
  resolution: PropTypes.string,
  chartType: PropTypes.number,
  isMobile: PropTypes.bool,
  serviceId: PropTypes.oneOf(ALL_SERVICES).isRequired,
  disableHeaderWidget: PropTypes.bool,
  withoutIndicators: PropTypes.bool,
  forceChartType: PropTypes.bool,
  autoSelectOrderPositions: PropTypes.bool,
  termId: PropTypes.string,
  selectionId: PropTypes.number,
  selectionVersion: PropTypes.number,
  onChangeLoading: PropTypes.func,
};
AutoSelectDetailChart.defaultProps = {
  isFullScreen: false,
  resolution: '',
  chartType: CHART_TYPES_MAIN.CANDLESTICK.ID,
  isMobile: false,
  disableHeaderWidget: false,
  withoutIndicators: false,
  forceChartType: false,
  autoSelectOrderPositions: false,
  termId: '',
  selectionId: 0,
  selectionVersion: 0,
  onChangeLoading: undefined,
};

export default memo(AutoSelectDetailChart);
