import { memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import { RANGE_OUT_ALERT_MESSAGE } from 'shared-modules/constants/select';
import { useOpenLink } from '../../../../../hooks';
import { Button, Spin } from '../../../../../components';
import { RangeOutAlertIcon } from '../../../../../components/Icons';
import SelectionImage from '../../../../../components/SelectionImage';
import styles from './header.module.scss';

export const Header = memo(({ strategyGroup, externalLinkUrl, externalLinkName, loadingSelections, loadingDetail }) => {
  const openLink = useOpenLink(externalLinkUrl, externalLinkName);
  const validExternalLink = Boolean(externalLinkUrl && externalLinkName);
  const displayedOnceRef = useRef(validExternalLink);

  useEffect(() => {
    if (validExternalLink) {
      displayedOnceRef.current = true;
    }
  }, [validExternalLink]);

  if (loadingSelections) {
    displayedOnceRef.current = false;
    return <Spin className={styles.loader} />;
  }
  const { image, serviceId, name, description, comment, labId, attribute } = strategyGroup;
  const { isRangeOut } = attribute ?? {};
  const isShare = labId != null;
  const displayDescription = isShare ? comment : description;

  return (
    <div className={styles.container}>
      <SelectionImage
        className={styles.image}
        width="32px"
        image={image}
        hasFrame={serviceId !== FX}
        isShare={isShare}
        serviceId={serviceId}
      />
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        {!!displayDescription && <div className={styles.description}>{displayDescription}</div>}
        {!!isRangeOut && (
          <div className={styles.rangeOutAlert}>
            <div className={styles.icon}>
              <RangeOutAlertIcon />
            </div>
            <div className={styles.alertText}>{RANGE_OUT_ALERT_MESSAGE}</div>
          </div>
        )}
      </div>
      <div className={styles.buttonArea}>
        {(validExternalLink || (loadingDetail && displayedOnceRef.current)) && (
          <Button className={styles.linkButton} onClick={openLink} loading={loadingDetail}>
            <div className={styles.linkCaption}>
              {externalLinkName}
              <i className={classNames('material-icons', styles.linkIcon)}>open_in_new</i>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
});

Header.propTypes = {
  strategyGroup: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    serviceId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    comment: PropTypes.string,
    labId: PropTypes.number,
    attribute: PropTypes.shape({
      closeTradeCount: PropTypes.number,
      isRangeOut: PropTypes.bool,
    }),
  }),
  externalLinkUrl: PropTypes.string,
  externalLinkName: PropTypes.string,
  loadingSelections: PropTypes.bool,
  loadingDetail: PropTypes.bool,
};

Header.defaultProps = {
  strategyGroup: undefined,
  externalLinkUrl: undefined,
  externalLinkName: undefined,
  loadingSelections: false,
  loadingDetail: false,
};
