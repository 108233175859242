import { memo } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { getBuilderUnit } from 'shared-modules/utils';
import styles from '../steps.module.scss';

const Step5 = ({ serviceId }) => {
  return (
    <div className={styles.content}>
      <div className={styles.mainContent}>
        <div>
          <p>
            作成される各自動売買注文ごとの注文数量を指定します。
            <br />
            {serviceId === FX && '1＝1万通貨'}
            <br />
            ビルダーでは、最小{getBuilderUnit(serviceId)}
            {serviceId !== CFD ? '単位' : ''}の取引が可能です。
          </p>
          <p className={styles.subContent}>
            <br />
            {serviceId === FX && '※南アランド/円とノルウェー/スウェーデンのみ10,000通貨単位'}
            {serviceId === ETF && '※TOPIX連動ETFのみ10口単位'}
          </p>
        </div>
      </div>
    </div>
  );
};

Step5.propTypes = {
  serviceId: propTypes.string,
};

Step5.defaultProps = {
  serviceId: '',
};

export default memo(Step5);
