import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetForm } from 'shared-modules/redux/labs';
import icon from '../../../../../assets/builder/builders_share.png';
import { useStartShareButtons } from '../hooks';
import styles from './welcome.module.scss';

export const Welcome = memo(() => {
  const dispatch = useDispatch();
  const buttons = useStartShareButtons();

  useEffect(() => {
    dispatch(resetForm());
  }, [dispatch]);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.title}>ビルダーズシェア</div>
        <img src={icon} className={styles.icon} alt="ビルダーズシェア" />
        <div className={styles.explanation}>
          <div>ビルダーで作ったルールをトライオート内でシェアしてみませんか？</div>
          <div>あなたのアイディアをぜひ皆さんにシェアしてください。</div>
        </div>
        <div className={styles.buttonArea}>
          {buttons.map(({ key, value }) => (
            <div key={key} className={styles.button}>
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
