/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useContext } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import { SelectedStrategyGroupContext } from '../contexts';
import {
  changeSelectedTermIdAutoTrade,
  clearSelectionModalInfo,
  getSelectionModalInfoRequest,
} from '../redux/actions/autoSelectActions';
import {
  changePublishedLabSelectedTermId,
  getPublishedLabDetailsRequest,
  resetPublishedLabDetails,
} from '../redux/labs';

export const useSelectedStrategyGroup = () => {
  const dispatch = useDispatch();
  const [strategyGroup, setData] = useContext(SelectedStrategyGroupContext);
  const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);
  const setStrategyGroup = useCallback(
    (newData) => {
      setData(newData);
      if (!tutorialMode) {
        dispatch(clearSelectionModalInfo());
      }
      if (newData != null) {
        const {
          selectionId,
          selectionVersion,
          labId,
          simulationStats: { termId },
        } = newData;
        if (labId == null) {
          // シェア以外のタグ
          if (selectionId != null && selectionVersion != null) {
            dispatch(changeSelectedTermIdAutoTrade({ termId: String(termId) }));
            dispatch(
              getSelectionModalInfoRequest({
                selectionId,
                selectionVersion,
                termId: String(termId),
              }),
            );
          }
        } else {
          // シェアタグ
          dispatch(resetPublishedLabDetails());
          dispatch(changePublishedLabSelectedTermId({ termId: String(termId) }));
          dispatch(getPublishedLabDetailsRequest({ labId, termId: String(termId), tradesFlg: true }));
        }
      }
    },
    [setData, tutorialMode, dispatch],
  );
  return [strategyGroup, setStrategyGroup];
};
