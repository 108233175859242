// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import { ORDER_METHOD_MAIN } from '../constants';

export const useIsMarkupTarget = ({ instrumentId, orderType, orderAmount }) => {
  const markups = useSelector((state) => state.markup.markups);

  const markupJudgeFromAmount = useMemo(() => {
    return markups.find(
      (markup) => markup.instrumentId === instrumentId && Number(markup.orderType) === Number(orderType),
    )?.judgeFromAmount;
  }, [markups, instrumentId, orderType]);

  return useMemo(() => {
    // マークアップ情報がない場合は注意喚起を出さない
    if (!markupJudgeFromAmount) return false;
    return markupJudgeFromAmount < Number(orderAmount);
  }, [markupJudgeFromAmount, orderAmount]);
};

export const useIsMarkupTargetBatchClose = ({ sumQuantityByInstrumentAndSide }) => {
  const markups = useSelector((state) => state.markup.markups);

  return useMemo(() => {
    if (!sumQuantityByInstrumentAndSide) return false;

    return sumQuantityByInstrumentAndSide.some(({ instrumentId, totalQuantity }) => {
      const markupJudgeFromAmount = markups.find(
        (markup) =>
          markup.instrumentId === instrumentId && Number(markup.orderType) === Number(ORDER_METHOD_MAIN.BATCH_CLOSE.ID),
      )?.judgeFromAmount;

      // マークアップ情報がない場合は注意喚起を出さない
      if (!markupJudgeFromAmount) return false;
      return markupJudgeFromAmount < Number(totalQuantity);
    });
  }, [sumQuantityByInstrumentAndSide, markups]);
};
