import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { BUTTON_ROLES, CFD, ETF, FX } from 'shared-modules/constants';
import {
  MAINTENANCE_INFOPAGE_URL,
  FORGOT_EMERGENCY_ID_URL,
  LOGIN_QUESTION_URL,
  OPEN_ACCOUNT,
  TWITTER_URL,
  FACEBOOK_URL,
  YOUTUBE_URL,
  LINE_URL,
  MY_PAGE_HOME_URL,
} from 'shared-modules/config';
import { checkIsWebApp } from 'shared-modules/services';

import {
  MAINTENANCE_LOGIN_ERROR_MESSAGE,
  MAINTENANCE_ERROR_MESSAGE_MOBILE,
  DISPLAYING_ERROR_IMS,
  IMS_200B_ERROR_MESSAGE,
} from 'shared-modules/constants/errorMesages';
import { IMS_LOGIN_ACCOUNT_TYPE } from 'shared-modules/constants/apiConstant';
import { fetchNotification } from 'shared-modules/api/generalApi';
import { getSettings } from 'shared-modules/api/settingsApi';
import { useKeyPressHandler } from '../../services/hooks';
import { loginUserRequest, resetLoginError } from '../../redux/actions';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';
import styles from './loginPage.module.scss';
import twitter from '../../assets/login/x-icon.png';
import line from '../../assets/login/LINE.png';
import youTube from '../../assets/login/YouTube.png';
import facebook from '../../assets/login/Facebook.png';
import トライオート_公式SNS from '../../assets/login/トライオート_公式SNS.png';
import mainPicture from '../../assets/login/PCWebログイン画面.png';
import { NotificationCard } from './NotificationCard';
import { MaintenanceErrorText } from './MaintenanceErrorText';

const LoginPage = () => {
  const dispatch = useDispatch();

  const [bannerTitle, setBannerTitle] = useState([]);
  const [maintenanceInfo, setMaintenanceInfo] = useState([]);

  const fetchBanner = useCallback(async () => {
    const { data } = await fetchNotification();
    setBannerTitle(data);
    return setBannerTitle;
  }, [setBannerTitle]);

  const getMaintenanceInfo = useCallback(async () => {
    const {
      data: {
        [ETF]: { system_status: etfSystemStatus },
        [FX]: { system_status: fxSystemStatus },
        [CFD]: { system_status: cfdSystemStatus },
      },
    } = await getSettings({ isPublic: true });
    setMaintenanceInfo({ etfSystemStatus, fxSystemStatus, cfdSystemStatus });
    return setMaintenanceInfo;
  }, [setMaintenanceInfo]);

  const isFxMaintenance = maintenanceInfo.fxSystemStatus === '2';
  const isEtfMaintenance = maintenanceInfo.etfSystemStatus === '2';
  const isCfdMaintenance = maintenanceInfo.cfdSystemStatus === '2';
  const isMaintenance = isEtfMaintenance && isFxMaintenance && isCfdMaintenance;

  const listContainer = useMemo(() => {
    return bannerTitle.map((item) => (
      <NotificationCard title={item.title} src={item.body} link={item.url} key={item.notificationId} />
    ));
  }, [bannerTitle]);

  const accountType = useSelector((state) => state.settings.loginMethod);
  const loginFlowIsLoading = useSelector((state) => state.auth.loginFlowIsLoading);
  useEffect(() => {
    fetchBanner();
    getMaintenanceInfo();
  }, [dispatch, fetchBanner, getMaintenanceInfo]);

  const [login, changeLogin] = useState('');
  const [password, changePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const error = useSelector((state) => state.auth.error);
  const errorMessages = useSelector((state) => state.auth.errorMessages);
  const loginErrorMessage = useSelector((state) => state.auth.loginErrorMessage);
  const isDefaultErrorMessage = IMS_200B_ERROR_MESSAGE === loginErrorMessage;

  const authIsLoading = useSelector((state) => state.auth.isLoading);
  const isEmergency = useMemo(() => accountType !== IMS_LOGIN_ACCOUNT_TYPE, [accountType]);

  const handleResetLoginError = useCallback(() => dispatch(resetLoginError({ name: 'login' })), [dispatch]);
  const handleResetPasswordError = useCallback(() => dispatch(resetLoginError({ name: 'password' })), [dispatch]);
  const openMyPageTab = useCallback(() => window.open(MY_PAGE_HOME_URL, '_blank'), []);
  const openForgotIdTab = useCallback(() => window.open(FORGOT_EMERGENCY_ID_URL, '_blank'), []);
  const openForgotIdTabByKey = useKeyPressHandler(openForgotIdTab);
  const openAccountTab = useCallback(() => window.open(OPEN_ACCOUNT, '_blank'), []);
  const openAccountTabByKey = useKeyPressHandler(openAccountTab);
  const loginQuestionTab = useCallback(() => window.open(LOGIN_QUESTION_URL, '_blank'), []);
  const loginQuestionTabByKey = useKeyPressHandler(loginQuestionTab);
  const twitterTab = useCallback(() => window.open(TWITTER_URL, '_blank'), []);
  const twitterTabByKey = useKeyPressHandler(twitterTab);
  const facebookTab = useCallback(() => window.open(FACEBOOK_URL, '_blank'), []);
  const facebookTabByKey = useKeyPressHandler(facebookTab);
  const youtubeTab = useCallback(() => window.open(YOUTUBE_URL, '_blank'), []);
  const youtubeTabByKey = useKeyPressHandler(youtubeTab);
  const lineTab = useCallback(() => window.open(LINE_URL, '_blank'), []);
  const lineTabByKey = useKeyPressHandler(lineTab);
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!login || !password || error || authIsLoading || isMaintenance) {
        return;
      }
      dispatch(
        loginUserRequest({
          login,
          password,
          accountType,
        }),
      );
    },
    [login, password, error, authIsLoading, isMaintenance, dispatch, accountType],
  );
  const maintenanceMessage = () => {
    const message = checkIsWebApp() ? MAINTENANCE_LOGIN_ERROR_MESSAGE : MAINTENANCE_ERROR_MESSAGE_MOBILE;
    return <MaintenanceErrorText message={message} linkURL={MAINTENANCE_INFOPAGE_URL} />;
  };

  return (
    <div className={styles.wrapper}>
      {!accountType || loginFlowIsLoading ? (
        <div className={classNames('spinner-border', styles.loader)} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.containerLeft}>
            <div className={styles.containerPicture}>
              <img src={mainPicture} className={styles.mainPicture} alt="mainPicture" />
            </div>
            <div className={styles.containerLeftSmall}>{listContainer}</div>
          </div>
          <div className={styles.containerRight}>
            <div className={styles.containerLogin}>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className={styles.loginForm}>
                  <div className={styles.loginTitle}>
                    <div className={styles.title}>LOG IN</div>
                    <div className={styles.subTitle}>トライオートの取引画面にログインします。</div>
                  </div>
                  <div className={styles.loginWrapper}>
                    <div className={styles.loginInput}>
                      <div>
                        <CustomInput
                          label=""
                          placeholder={isEmergency ? '取引用ID' : 'メールアドレスまたはMyページログインID'}
                          value={login}
                          onChange={changeLogin}
                          name="login"
                          errorMessages={errorMessages}
                          validateFunction={handleResetLoginError}
                          inputClassName={styles.input}
                          errorClassName={styles.idError}
                        />
                      </div>
                      <div className={styles.personIconWrapper}>
                        <i className={classNames('material-icons', styles.personIcon)}>person</i>
                      </div>
                    </div>
                    <div className={styles.loginInput}>
                      <div>
                        <CustomInput
                          label=""
                          placeholder={isEmergency ? 'パスワード' : 'Myページパスワード'}
                          value={password}
                          onChange={changePassword}
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          errorMessages={errorMessages}
                          validateFunction={handleResetPasswordError}
                          inputClassName={styles.input}
                          errorClassName={styles.pwError}
                        />
                      </div>
                      <div className={styles.lockIconWrapper}>
                        <i className={classNames('material-icons', styles.lockIcon)}>lock</i>
                      </div>
                      <div className={styles.eyeIconWrapper}>
                        <span
                          className={styles.eyeIcon}
                          onClick={() => setShowPassword(!showPassword)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setShowPassword(!showPassword);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          {showPassword ? (
                            <i className="material-icons">visibility_off</i>
                          ) : (
                            <i className="material-icons">visibility</i>
                          )}
                        </span>
                      </div>
                    </div>
                    {loginErrorMessage && <div className={styles.emergencyText}>{loginErrorMessage}</div>}
                    {isDefaultErrorMessage && (
                      <div className={styles.emergencyText}>
                        初期パスワードの場合は
                        <div className={styles.emergencyTextLink} onClick={openMyPageTab} role="button" aria-hidden>
                          Myページ
                        </div>
                        にログインしてパスワードを変更してください。
                      </div>
                    )}
                    {isMaintenance && <div className={styles.maintenanceText}>{maintenanceMessage()}</div>}
                    {isEmergency && (
                      <div className={styles.imsEmergencyText}>
                        <span /> {DISPLAYING_ERROR_IMS}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <CustomButton
                    isDisabled={!login || !password || error}
                    isLoading={authIsLoading}
                    buttonRole={BUTTON_ROLES.SUBMIT}
                    buttonClassName={login && password ? styles.conbutton : styles.DisabledConbutton}
                  >
                    ログイン
                  </CustomButton>
                </div>
              </form>
              <div className={styles.containerlink}>
                <div>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={openAccountTab}
                    onKeyPress={openAccountTabByKey}
                    className={styles.maintenanceLink}
                  >
                    口座開設はこちら
                  </span>
                </div>
                <div className={styles.linkInfo}>
                  <div className={styles.link}>
                    <span role="button" tabIndex={0} onClick={openForgotIdTab} onKeyPress={openForgotIdTabByKey}>
                      ログインにお困りの方
                    </span>
                  </div>
                  <div className={styles.link}>
                    <span role="button" tabIndex={0} onClick={loginQuestionTab} onKeyPress={loginQuestionTabByKey}>
                      ログインに関するご質問
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.containerRightSmall}>
              <div className={styles.rightSmallItem}>
                <img src={トライオート_公式SNS} alt="triautoLogo" />
              </div>
              <div className={styles.rightSmallItem}>
                <div className={styles.iconsWrapper}>
                  <span role="button" tabIndex={0} onClick={twitterTab} onKeyPress={twitterTabByKey}>
                    <img src={twitter} className={classNames(styles.snsIcons, styles.xIcon)} alt="twitterアイコン" />
                  </span>
                  <span role="button" tabIndex={0} onClick={facebookTab} onKeyPress={facebookTabByKey}>
                    <img src={facebook} className={styles.snsIcons} alt="facebookアイコン" />
                  </span>
                  <span role="button" tabIndex={0} onClick={youtubeTab} onKeyPress={youtubeTabByKey}>
                    <img src={youTube} className={styles.snsIcons} alt="youTubeアイコン" />
                  </span>
                  <span role="button" tabIndex={0} onClick={lineTab} onKeyPress={lineTabByKey}>
                    <img src={line} className={styles.snsIcons} alt="lineアイコン" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(LoginPage);
