import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PositionQuickCloseButton from './components/PositionQuickCloseButton';

const CellPortfolioPositionTableActions = ({ row, isForInstrument }) => {
  const { positionId, apGroupId, instrumentId, quantity } = row.original;
  const usedInstrumentId = isForInstrument ? instrumentId : null;

  return (
    <PositionQuickCloseButton
      positionId={positionId}
      apGroupId={apGroupId}
      instrumentId={usedInstrumentId}
      quantity={quantity}
    />
  );
};

CellPortfolioPositionTableActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      positionId: PropTypes.string,
      apGroupId: PropTypes.string,
      instrumentId: PropTypes.string,
      quantity: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isForInstrument: PropTypes.bool,
};

CellPortfolioPositionTableActions.defaultProps = {
  isForInstrument: false,
};

export default memo(CellPortfolioPositionTableActions);
