import { call, put, takeEvery } from 'redux-saga/effects';
import { getMarkups } from '../../api/markupApi';
import { errorHandler } from './errorSaga';
import { CFD, ETF, FX } from '../../constants';
import { getAccountInfo } from './common';
import { getAllMarkupsSuccess } from '../actions/markupActions';
import { GET_ALL_MARKUPS_REQUEST } from '../actionConstants/markupConstants';

function* getAllMarkupsRequestHandler() {
  try {
    const accountInfo = yield* getAccountInfo();
    const markups = [];
    if (!accountInfo[FX].isMaintenance) {
      const { data: FXMarkups } = yield call(getMarkups, { serviceId: FX });
      markups.push(...FXMarkups);
    }

    if (!accountInfo[ETF].isMaintenance && !accountInfo[ETF].notExist) {
      const { data: ETFMarkups } = yield call(getMarkups, { serviceId: ETF });
      markups.push(...ETFMarkups);
    }

    if (!accountInfo[CFD].isMaintenance && !accountInfo[CFD].notExist) {
      const { data: CFDMarkups } = yield call(getMarkups, { serviceId: CFD });
      markups.push(...CFDMarkups);
    }
    yield put(getAllMarkupsSuccess({ markups }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  }
}

export default function* markupsSaga() {
  yield takeEvery(GET_ALL_MARKUPS_REQUEST, getAllMarkupsRequestHandler);
}
