import React, { memo, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatNumberToDisplayedString,
  getFxCfdQuantityStep,
  getPriceStepByServiceId,
  getServiceQuantityUnit,
} from 'shared-modules/services';
import { ORDER_TYPES, PRICE_INPUT, COUNT_INPUT, TIME_INPUT, DATE_INPUT } from 'shared-modules/constants/manualTrade';
import {
  OPTIONS_ORDER_METHOD,
  OPTIONS_EXPIRATION_TYPE,
  EXPIRATION_TYPE_MAIN,
  STANDARD_ORDER_METHOD_SELECTOR,
  STANDARD_EXPIRATION_DATE_SELECTOR,
  ETF,
} from 'shared-modules/constants';
import {
  useTimeChangingHandler,
  useInputCreateOrder,
  useBuySellInputCreateOrder,
  useManualTradeSelectedInstrumentSettings,
  useOrderValidationErrors,
  useValidateSelectedManualTradeOrder,
  useValidateManualTradeInput,
  useBuySellOptionRestrictions,
  useCalculateManualMargin,
} from 'shared-modules/services/hooks';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { useIsMarkupTarget } from 'shared-modules/hooks/markup';
import { SELECT_WIDTH, TIME_INPUT_WIDTH, DATEPICKER_WIDTH } from '../../constants';
import { toggleUserSettingNewOrderSkipConfirmationRequest } from '../../../../redux/actions';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomSelect from '../../../../components/CustomSelect';
import CustomInput from '../../../../components/CustomInput';
import LabeledSwitch from '../../../../components/LabeledSwitch';
import styles from './sideMenuOptionsStandard.module.scss';
import { InputNumber, Switch, BuySellGroupButton } from '../../../../components';
import { QuantityLabel } from '../../../../components/QuantityLabel';

const SideMenuOptionsStandard = () => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipNewOrderConfirmation);

  const instrumentId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);

  const { quantityPrecision, pricePrecision, allowBuyFlg, allowSellFlg, quantityUnitConvFactor } =
    useManualTradeSelectedInstrumentSettings();

  const isETF = serviceId === ETF;
  const quantityIsInteger = isETF && quantityPrecision >= 1;

  const priceStep = useMemo(
    () => getPriceStepByServiceId(serviceId, instrumentId, pricePrecision),
    [pricePrecision, instrumentId, serviceId],
  );
  const priceIsInteger = useMemo(() => isETF && priceStep >= 1, [isETF, priceStep]);

  const currentDate = useMemo(() => new Date(), []);

  const validateSelectedManualTradeOrder = useValidateSelectedManualTradeOrder();
  const validateSelectedManualTradeOrderRef = useRef({});
  useEffect(() => {
    validateSelectedManualTradeOrderRef.current = { validateSelectedManualTradeOrder };
  }, [validateSelectedManualTradeOrder]);

  const validateManualTradeInput = useValidateManualTradeInput();
  const validateManualTradeInputRef = useRef({});
  useEffect(() => {
    validateManualTradeInputRef.current = { validateManualTradeInput };
  }, [validateManualTradeInput]);

  const validationErrors = useOrderValidationErrors(ORDER_TYPES.STANDARD.name);

  const handleChangeWithoutConfirmation = useCallback(() => {
    dispatch(toggleUserSettingNewOrderSkipConfirmationRequest());
  }, [dispatch]);

  const [buySell, changeBuySell] = useBuySellInputCreateOrder(ORDER_TYPES.STANDARD.name);
  useBuySellOptionRestrictions(allowBuyFlg, allowSellFlg, buySell, changeBuySell);
  const [count, changeCount] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.COUNT,
    quantityPrecision,
    true,
  );
  const [orderMethod, changeOrderMethod] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.ORDER_METHOD,
  );

  const [price, changePrice] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.PRICE,
    pricePrecision,
  );
  const priceRef = useRef({});
  useEffect(() => {
    priceRef.current = { price };
  }, [price]);

  const [expirationType, changeExpirationType] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.EXPIRATION_TYPE,
    null,
    true,
  );

  const [selectedDate, setSelectedDate] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.SELECTED_DATE,
    null,
    true,
  );

  const [selectedTime, setSelectedTime] = useInputCreateOrder(
    ORDER_TYPES.STANDARD.name,
    ORDER_TYPES.STANDARD.inputs.SELECTED_TIME,
    null,
    true,
  );

  const onTimeChange = useTimeChangingHandler(setSelectedTime);

  const calculatedMargin = useCalculateManualMargin(count, price);

  // validate all values on orderMethod change
  useEffect(() => {
    const { validateSelectedManualTradeOrder: validate } = validateSelectedManualTradeOrderRef?.current;
    if (validate) {
      validate({ changedType: orderMethod });
    }
  }, [orderMethod]);

  const isMarkupTarget = useIsMarkupTarget({
    instrumentId,
    orderType: orderMethod,
    orderAmount: count,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>新規</div>
      <div className={styles.buySellRow}>
        売買
        <BuySellGroupButton
          instrumentId={instrumentId}
          buySell={buySell}
          onChange={changeBuySell}
          disabledSell={!allowSellFlg}
          disabledBuy={!allowBuyFlg}
        />
      </div>
      <div className={styles.orderMethodRow}>
        注文条件
        <LabeledSwitch
          activeItemId={orderMethod}
          onChange={changeOrderMethod}
          options={OPTIONS_ORDER_METHOD}
          isLighter
          itemClassName={styles.itemClassName}
          dataCustomSelector={STANDARD_ORDER_METHOD_SELECTOR}
        />
      </div>
      <div className={styles.countRow}>
        <QuantityLabel
          serviceId={serviceId}
          quantityUnit={quantityUnit}
          quantityUnitConvFactor={quantityUnitConvFactor}
        />
        <InputNumber
          value={count}
          onChange={changeCount}
          name={COUNT_INPUT}
          errorMessages={validationErrors}
          type="number"
          step={isETF ? quantityPrecision : getFxCfdQuantityStep}
          onlyIntegerAllowed={quantityIsInteger}
          withErrorTooltip
        />
      </div>
      {isMarkupTarget && (
        <div className={styles.makeupsWarningRow}>
          <div className={styles.inner}>
            逆指値注文を設定し、かつ数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは
            <a className={styles.button} href={MARKUPS_URL} target="_blank" rel="noopener noreferrer">
              こちら
            </a>
          </div>
        </div>
      )}
      <div className={styles.necessaryMarginRow}>
        発注証拠金目安
        <div className={styles.necessaryMargin}>
          {formatNumberToDisplayedString({ value: calculatedMargin, withoutPlus: true })}
        </div>
      </div>
      <div className={styles.priceRow}>
        価格
        <InputNumber
          value={price}
          name={PRICE_INPUT}
          errorMessages={validationErrors}
          onChange={changePrice}
          type="number"
          step={priceStep}
          onlyIntegerAllowed={priceIsInteger}
          withErrorTooltip
        />
      </div>
      <div className={styles.expirationTypeRow}>
        有効期限
        <CustomSelect
          instanceId="side-menu-options-standard-expiration-date"
          selectItemId={expirationType}
          onChange={changeExpirationType}
          options={OPTIONS_EXPIRATION_TYPE}
          isLighter
          width={SELECT_WIDTH}
          dataCustomSelector={STANDARD_EXPIRATION_DATE_SELECTOR}
          menuPlacement="top"
          isDarker
        />
      </div>
      {expirationType === EXPIRATION_TYPE_MAIN.CUSTOM.ID && (
        <>
          <div className={styles.datePickerRow}>
            日付
            <CustomDatePicker
              width={DATEPICKER_WIDTH}
              date={selectedDate}
              onChange={setSelectedDate}
              name={DATE_INPUT}
              errorMessages={validationErrors}
              popperPlacement="auto"
              isTaller
              disableSundays
              minDate={currentDate}
            />
          </div>
          <div className={styles.timePickerRow}>
            時刻
            <CustomInput
              value={selectedTime}
              onChange={onTimeChange}
              width={TIME_INPUT_WIDTH}
              name={TIME_INPUT}
              errorMessages={validationErrors}
              withErrorTooltip
              placeholder="00:00"
              isEndAligned
            />
          </div>
        </>
      )}
      <div className={styles.countRow}>
        確認省略
        <Switch checked={withoutConfirmation} onChange={handleChangeWithoutConfirmation} />
      </div>
    </div>
  );
};

export default memo(SideMenuOptionsStandard);
