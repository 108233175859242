import { GET_ALL_MARKUPS_SUCCESS } from '../actionConstants/markupConstants';

export const initialState = Object.freeze({
  markups: [],
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_MARKUPS_SUCCESS: {
      const { markups } = payload;
      return {
        ...state,
        markups,
      };
    }
    default: {
      return state;
    }
  }
};
