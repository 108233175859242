import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelectionDetail } from 'shared-modules/hooks/select';
import { AUTO_SELECT_TAB_LOGIC_SETTING, AUTO_SELECT_TAB_SIMULATION } from 'shared-modules/constants';
import { ADD_TO_CART, MESSAGE_NO_RESULT, RUN_NOW } from 'shared-modules/constants/select';
import { useDispatch, useSelector } from 'react-redux';
import { getJapaneseFormatDate } from 'shared-modules/services';
import {
  tutorialAddCart,
  tutorialRunNow,
  tutorialSimulationTab,
  tutorialLogicSettingTab,
} from '../../../../constants/tutorial/classNames';
import { Button, LabelWithHelp, Tabs, Spin } from '../../../../components';
import { useAddToCart, useRunNow } from '../../hooks';
import { LogicSetting } from '../LogicSetting';
import { ConfirmModal } from '../ConfirmModal';
import { Simulation } from '../Simulation';
import { ChartDescription } from '../ChartDescription';
import { Header } from './Header';
import styles from './detail.module.scss';
import { openAutoSelectWarningInfo } from '../../../../redux/actions';

const TAB_ITEMS = [
  {
    id: AUTO_SELECT_TAB_SIMULATION,
    className: tutorialSimulationTab,
    label: 'シミュレーション',
    children: <Simulation />,
  },
  {
    id: AUTO_SELECT_TAB_LOGIC_SETTING,
    className: tutorialLogicSettingTab,
    label: 'ロジック設定',
    children: <LogicSetting />,
  },
];

export const Detail = memo(() => {
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(AUTO_SELECT_TAB_SIMULATION);
  const [visibleAddToCartConfirmModal, setVisibleAddToCartConfirmModal] = useState(false);
  const [visibleRunNowConfirmModal, setVisibleRunNowConfirmModal] = useState(false);
  const activeSelections = useSelector((state) => state.autoSelect.activeSelections);
  const publishedLabsList = useSelector((state) => state.labs.publishedLabs.list);

  const {
    strategyGroup,
    externalLinkUrl,
    externalLinkName,
    loadingSelections,
    loadingAddToCart,
    loadingRunNow,
    loadingDetail,
    loadingDetailTerm,
    loadingDetailLab,
    isSoldOut,
  } = useSelectionDetail();

  const notSelected = strategyGroup == null;

  const handleClickAddToCart = useAddToCart();

  const handleClickAddToCartWithConfirm = useCallback(() => {
    if (strategyGroup == null) {
      return;
    }
    if (strategyGroup.labId == null) {
      handleClickAddToCart();
    } else {
      setVisibleAddToCartConfirmModal(true);
    }
  }, [handleClickAddToCart, strategyGroup]);

  const handleCloseAddToCartConfirmModal = useCallback(() => {
    setVisibleAddToCartConfirmModal(false);
  }, []);

  const handleClickRunNow = useRunNow();

  const handleClickRunNowWithConfirm = useCallback(() => {
    if (strategyGroup == null) {
      return;
    }
    if (strategyGroup.labId == null) {
      handleClickRunNow();
    } else {
      setVisibleRunNowConfirmModal(true);
    }
  }, [handleClickRunNow, strategyGroup]);

  const handleCloseRunNowConfirmModal = useCallback(() => {
    setVisibleRunNowConfirmModal(false);
  }, []);

  const handleOpenWarning = useCallback(
    () =>
      dispatch(
        openAutoSelectWarningInfo({
          termEndDate: getJapaneseFormatDate(strategyGroup?.simulationStats?.termEnd),
          isLabPreview: false,
        }),
      ),
    [dispatch, strategyGroup?.simulationStats?.termEnd],
  );

  useEffect(() => {
    if (!window.ResizeObserver || loadingSelections || loadingDetail || loadingDetailLab || strategyGroup == null) {
      return () => {};
    }
    const header = headerRef.current;
    const rect = headerRef.current?.getBoundingClientRect();
    setHeaderHeight(rect?.height ?? 0);
    const observer = new window.ResizeObserver((entries) => {
      const [target] = entries;
      const { height = 0 } = target?.contentRect ?? {};
      setHeaderHeight(height);
    });
    observer.observe(header);
    return () => {
      observer.unobserve(header);
    };
  }, [loadingSelections, loadingDetail, loadingDetailLab, strategyGroup]);

  useEffect(() => {
    if (loadingSelections || loadingDetail || loadingDetailLab) {
      setActiveTab(AUTO_SELECT_TAB_SIMULATION);
    }
  }, [loadingSelections, loadingDetail, loadingDetailLab]);

  const toolbar = useMemo(() => {
    if (activeTab !== AUTO_SELECT_TAB_LOGIC_SETTING) {
      return <LabelWithHelp className={styles.help} label="注意事項" onClick={handleOpenWarning} />;
    }
    return <ChartDescription className={styles.toolbar} />;
  }, [activeTab, handleOpenWarning]);

  if (
    loadingSelections ||
    loadingDetail ||
    loadingDetailLab ||
    (strategyGroup == null && (activeSelections.length || publishedLabsList.length))
  ) {
    return <Spin className={styles.loader} />;
  }

  if (strategyGroup == null) {
    return <div className={styles.empty}>{MESSAGE_NO_RESULT}</div>;
  }

  return (
    <>
      <div className={styles.container}>
        <div ref={headerRef} className={styles.header}>
          <Header
            strategyGroup={strategyGroup}
            externalLinkUrl={externalLinkUrl}
            externalLinkName={externalLinkName}
            loadingSelections={loadingSelections}
            // シェアは外部リンクをAPIで取得していないため、loadingDetailLab は見ない
            loadingDetail={loadingDetail || loadingDetailTerm}
          />
        </div>
        <div className={styles.content} style={{ height: `calc(100% - ${headerHeight}px - 60px)` }}>
          <div className={styles.tabArea}>
            <Tabs
              tabsClassName={styles.tabs}
              items={TAB_ITEMS}
              activeKey={activeTab}
              onChange={setActiveTab}
              toolbar={toolbar}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            className={tutorialAddCart}
            width={200}
            onClick={handleClickAddToCartWithConfirm}
            loading={loadingAddToCart}
            disabled={notSelected || isSoldOut}
            secondary
          >
            {ADD_TO_CART}
          </Button>
          <Button
            className={tutorialRunNow}
            width={200}
            onClick={handleClickRunNowWithConfirm}
            loading={loadingRunNow}
            disabled={notSelected}
          >
            {RUN_NOW}
          </Button>
        </div>
      </div>
      {visibleAddToCartConfirmModal && (
        <ConfirmModal
          visible={visibleAddToCartConfirmModal}
          onClose={handleCloseAddToCartConfirmModal}
          onClick={handleClickAddToCart}
        />
      )}
      {visibleRunNowConfirmModal && (
        <ConfirmModal
          visible={visibleRunNowConfirmModal}
          onClose={handleCloseRunNowConfirmModal}
          onClick={handleClickRunNow}
        />
      )}
    </>
  );
});
