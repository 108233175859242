import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useCheckOnPositionIsBeingDeleted, useGetStillDeletingPositions } from 'shared-modules/services/hooks';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { ORDER_METHOD_MAIN } from 'shared-modules/constants';
import { useIsMarkupTarget } from 'shared-modules/hooks/markup';
import {
  openConfirmationModal,
  deletePositionsOrderById,
  saveDeletingPositions,
} from '../../../../../../redux/actions';
import CustomButton from '../../../../../../components/CustomButton';

const PositionQuickCloseButton = ({ positionId, apGroupId, instrumentId, quantity }) => {
  const dispatch = useDispatch();

  const checkOnPositionIsBeingDeleted = useCheckOnPositionIsBeingDeleted();
  const getStillDeletingPositions = useGetStillDeletingPositions();

  const isMarkupTarget = useIsMarkupTarget({
    instrumentId,
    orderType: ORDER_METHOD_MAIN.MARKET.ID,
    orderAmount: quantity,
  });

  const closeOrder = useCallback(() => {
    const payload = {
      orderId: positionId,
      apGroupId,
      instrumentId,
      callback: () => {
        const stillDeletingPositions = getStillDeletingPositions();

        // save position IDs with timestamps in order to prevent clicking its checkboxes for two minutes
        const positions = [{ positionId, deletionStartedAt: Date.now() }, ...stillDeletingPositions];

        dispatch(saveDeletingPositions({ positions }));
      },
    };

    if (instrumentId) payload.instrumentId = instrumentId;

    dispatch(deletePositionsOrderById(payload));
  }, [positionId, apGroupId, instrumentId, dispatch, getStillDeletingPositions]);

  const handleClick = useCallback(() => {
    checkOnPositionIsBeingDeleted({
      positionId,
      successCallback: () => {
        dispatch(
          openConfirmationModal({
            title: 'クイック注文決済',
            bodyText: (
              <>
                <p>注文中の決済注文を取り消し、全建玉数量の成行決済注文を発注いたします。よろしいでしょうか？</p>
                {isMarkupTarget && (
                  <div>
                    決済建玉の数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは
                    <a
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textDecorationStyle: 'solid',
                        textDecorationThickness: '0%',
                      }}
                      href={MARKUPS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </a>
                  </div>
                )}
              </>
            ),
            callback: closeOrder,
            buttonBackText: '戻る',
            buttonNextText: '決済',
            isOverlap: true,
          }),
        );
      },
    });
  }, [checkOnPositionIsBeingDeleted, positionId, dispatch, closeOrder, isMarkupTarget]);

  return (
    <CustomButton isSmall onClick={handleClick}>
      クイック
    </CustomButton>
  );
};

PositionQuickCloseButton.propTypes = {
  positionId: PropTypes.string.isRequired,
  apGroupId: PropTypes.string,
  instrumentId: PropTypes.string,
  quantity: PropTypes.number,
};

PositionQuickCloseButton.defaultProps = {
  instrumentId: null,
  apGroupId: null,
  quantity: null,
};

export default memo(PositionQuickCloseButton);
