/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
import { CFD, ETF, FX } from '../constants';
import { useServiceName } from '.';
import { getServiceQuantityUnit } from '../services';
import { useGetBuilderServiceId } from '../services/hooks/builder';

const getQuantityTooltip = (serviceId) => {
  if (serviceId === FX) {
    // eslint-disable-next-line max-len
    return '各自動売買注文ごとの注文数量を指定します。<br>1＝1万通貨<br>ビルダーでは、最小0.1（万）＝1,000通貨単位の取引が可能です。<br>※南アランド/円とノルウェー/スウェーデンのみ10,000通貨単位';
  }
  if (serviceId === ETF) {
    return '各自動売買注文ごとの注文数量を指定します。<br>ビルダーでは、最小1口単位の取引が可能です。<br>※TOPIX連動ETFのみ10口単位';
  }
  if (serviceId === CFD) {
    return '各自動売買注文ごとの注文数量を指定します。<br>ビルダーでは、最小0.1Lotの取引が可能です。';
  }
  return null;
};

export const getEntryPricePipsValueMessage = (serviceId) => {
  if (serviceId === FX) {
    return '値幅はpipsで指定します。';
  }
  if (serviceId === ETF) {
    return '値幅は円（日本銘柄）またはドル(米国銘柄）で指定します。';
  }
  if (serviceId === CFD) {
    return '';
  }
  return null;
};

export const useBuilderTooltips = (isTech) => {
  const imagePrefix = useMemo(() => (isTech ? 'tech' : 'multi'), [isTech]);
  const serviceId = useGetBuilderServiceId();
  const serviceName = useServiceName(serviceId);
  const isFX = serviceId === FX;
  const quantityUnit = getServiceQuantityUnit(serviceId);
  return {
    buySell: {
      label: '売買',
      tooltipMessage:
        '自動売買注文の新規取引の方向を選びます。<br>買：価格の上昇で利益を得ます（新規買い → 決済売り）<br>売：価格の下落で利益を得ます（新規売り → 決済買い）',
      descriptionImage: `multi${serviceName}_buySell.png`,
    },
    rangeSpread: {
      label: 'レンジ幅',
      tooltipMessage: isFX
        ? '複数の注文を設定するレンジの幅を指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '複数の注文を設定するレンジの幅を指定します。',
      descriptionImage: `${imagePrefix}${serviceName}_rangeSpread.png`,
    },
    itemsCount: {
      label: '本数',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '指定したレンジ幅の中に何本の注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔（pips）＝レンジ幅÷本数<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '指定したレンジ幅の中に何本の注文を設定するかを決めます。注文は等間隔に設定されます。<br>注文間隔＝レンジ幅÷本数',
      descriptionImage: `${imagePrefix}${serviceName}_items_count.png`,
    },
    quantity: {
      label: `数量(${quantityUnit})`,
      tooltipMessage: getQuantityTooltip(serviceId),
      descriptionImage: `multi${serviceName}_quantity.png`,
    },
    entryPricePipsValue: {
      label: 'スタート価格',
      // eslint-disable-next-line max-len, prettier/prettier
      tooltipMessage: `複数の注文を設定するレンジ幅の開始位置を指定します。<br><br>【買いの場合】<br>レンジ幅の上限値を意味します。上限値から買い下がるように指定した本数の買い注文が設定されます。<br>【売りの場合】<br>レンジ幅の下限値を意味します。下限値から売り上がるように指定した本数の売り注文が設定されます。<br>【価格指定】<br>スタート価格を価格で指定します。<br>【前日終値】<br>前日終値から指定した値幅分離れたところにスタート価格を設定します。${getEntryPricePipsValueMessage(
        serviceId,
      )}前日終値はチャート上の緑のラインです。`,
      descriptionImage: `multi${serviceName}_entry.png`,
    },
    ocoCheckbox: {
      label: 'スタート価格：OCO',
      // label: 'スタート価格: OCO',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? 'スタート価格を挟んで指定したレンジ幅の２倍の範囲に初回注文をOCOで設定します。 OCO注文とは、1つの注文で2つの価格を同時に発注する手法です。どちらかが成立すると、もう一方は自動的にキャンセルになります。<br>【買いの場合】<br>スタート価格から上の価格帯にもレンジ幅分の注文が設定されます。<br>【売りの場合】<br>スタート価格から下の価格帯にもレンジ幅分の注文が設定されます。' // eslint-disable-next-line max-len
        : 'スタート価格を挟んで指定したレンジ幅の２倍の範囲に初回注文をOCOで設定します。 OCO注文とは、1つの注文で2つの価格を同時に発注する手法です。どちらかが成立すると、もう一方は自動的にキャンセルになります。<br>【買いの場合】<br>スタート価格から上の価格帯にもレンジ幅分の注文が設定されます。<br>【売りの場合】<br>スタート価格から下の価格帯にもレンジ幅分の注文が設定されます。',
      descriptionImage: `multi${serviceName}_entry_oco.png`,
    },
    profitMargin: {
      label: '利確幅',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅（pips）で指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '保有中の建玉が買った（売った）価格から利益の方向にどのくらい相場が動いたら利益確定（利確）するかを値幅で指定します。',
      descriptionImage: `multi${serviceName}_profitMargin.png`,
    },
    stopLossCheckbox: {
      label: '損切幅',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅（pips）で指定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : '保有中の建玉が買った（売った）価格からどのくらい損失の方向に相場が動いたら損失確定（損切り）するかを値幅で指定します。',
      descriptionImage: `multi${serviceName}_stopLoss.png`,
    },
    followCheckbox: {
      label: 'フォロー値',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? 'フォロー値は、買いの場合は決済価格からどのくらい上がったら、次新たに買うかを値幅（pips）で設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい下がったらまた売るかを設定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）'
        : 'フォロー値は、買いの場合は決済価格からどのくらい上がったら、次新たに買うかを値幅で設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい下がったらまた売るかを設定します。',
      descriptionImage: `multi${serviceName}_follow.png`,
    },
    counterCheckbox: {
      label: 'カウンター値',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? 'カウンター値は、買いの場合は決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい上がったらまた売るかを設定します。<br>※pips：10pips＝0.1円（対円通貨ペア）、0.001外貨（対円以外の通貨ペア）' // eslint-disable-next-line max-len
        : 'カウンター値は、買いの場合は決済価格からどのくらい下がったら、次新たに買うかを設定する、再エントリーの条件になります。<br>売りの場合は反対で、決済価格からどのくらい上がったらまた売るかを設定します。',
      descriptionImage: `multi${serviceName}_counter.png`,
    },
    counterFixedCheckbox: {
      label: 'カウンター固定',
      tooltipMessage: isFX // eslint-disable-next-line max-len
        ? 'チェックを入れた場合は、カウンター値が価格固定となり、決済後、各注文はそれぞれ決まった価格で新規発注を繰り返すことができます。<br>OFFの場合は、決済価格からカウンター値の値幅分（pips）離れたところに新規発注します。' // eslint-disable-next-line max-len
        : 'チェックを入れた場合は、カウンター値が価格固定となり、決済後、各注文はそれぞれ決まった価格で新規発注を繰り返すことができます。<br>OFFの場合は、決済価格からカウンター値の値幅分離れたところに新規発注します。',
      descriptionImage: `multi${serviceName}_counter_fix.png`,
    },
  };
};
