import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './logo.module.scss';
import triautoLogo from '../../../../assets/logos/header_triauto_logo.png';

const Logo = ({ onClick }) => (
  <div onClick={onClick} className={styles.logos} role="button" aria-hidden>
    <img src={triautoLogo} className={styles.triautoLogo} alt="triautoLogo" />
  </div>
);

Logo.propTypes = {
  onClick: PropTypes.func,
};
Logo.defaultProps = {
  onClick: null,
};

export default memo(Logo);
