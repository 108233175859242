import React, { memo, useCallback, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  getServiceQuantityUnit,
  getDefaultValuesFromLocalStorage,
  saveDefaultValuesFromLocalStorage,
  getFxCfdQuantityStep,
} from 'shared-modules/services';
import { ORDER_TYPES, COUNT_INPUT } from 'shared-modules/constants/manualTrade';
import { TRADE_METHODS, KEY_FOR_IS_CROSS_ORDER, ETF, ORDER_METHOD_MAIN } from 'shared-modules/constants';
import {
  useInputCreateOrder,
  useBuySellInputCreateOrder,
  useManualTradeSelectedInstrumentSettings,
  useValidateSelectedManualTradeOrder,
  useBuySellOptionRestrictions,
} from 'shared-modules/services/hooks';
import { useManualTradeFifoTableLogic } from 'shared-modules/services/hooks/manualTradeFifoTableLogic';
import { toggleUserSettingCrossOrder } from 'shared-modules/redux/actions/settingsActions';
import { MARKUPS_URL } from 'shared-modules/constants/markup';
import { useIsMarkupTarget } from 'shared-modules/hooks/markup';
import { openConfirmationModal, toggleUserSettingNewOrderSkipConfirmationRequest } from '../../../../redux/actions';

import styles from './sideMenuOptionsMarkerOrder.module.scss';
import { InputNumber, Switch } from '../../../../components';
import { QuantityLabel } from '../../../../components/QuantityLabel';

const SideMenuOptionsMarkerOrder = () => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const withoutConfirmation = useSelector((state) => state.settings[serviceId].skipNewOrderConfirmation);
  const isCrossOrder = useSelector((state) => state.settings[serviceId].isCrossOrder);
  const selectedCurrencyId = useSelector((state) => state.manualTrade.selectedInstrumentId[serviceId]);
  const instrumentSetting = useSelector((state) => state.settings.instrumentList[selectedCurrencyId]);

  const { quantityPrecision, allowBuyFlg, allowSellFlg } = useManualTradeSelectedInstrumentSettings();
  const isETF = serviceId === ETF;
  const quantityIsInteger = isETF && quantityPrecision >= 1;

  const handleChangeWithoutConfirmation = useCallback(() => {
    dispatch(toggleUserSettingNewOrderSkipConfirmationRequest());
  }, [dispatch]);

  const handleChangeCrossOrder = useCallback(() => {
    dispatch(toggleUserSettingCrossOrder({ value: !isCrossOrder, serviceId }));
    saveDefaultValuesFromLocalStorage({
      key: KEY_FOR_IS_CROSS_ORDER[serviceId],
      value: `${!isCrossOrder}`,
    });
  }, [dispatch, isCrossOrder, serviceId]);

  const confirmHandleChangeCrossOrder = useCallback(() => {
    dispatch(
      openConfirmationModal({
        title: 'ご注意',
        bodyText: `【両建】OFF
        建玉保有時に反対方向の売買を行うと決済注文となり古い建玉から決済されます。
        建玉を保有していない場合は新規注文となります。
        
        【両建】ON
        注文は全て新規注文となります。`,
        callback: handleChangeCrossOrder,
        buttonBackText: '戻る',
        buttonNextText: '変更',
        isOverlap: true,
      }),
    );
  }, [dispatch, handleChangeCrossOrder]);

  const sellQuantityMax = useMemo(
    () => instrumentSetting?.settings?.[TRADE_METHODS.MANUAL.ID].sellQuantityMax,
    [instrumentSetting],
  );
  const isSellDisabled = useMemo(() => Number(sellQuantityMax) === 0, [sellQuantityMax]);

  useEffect(() => {
    const saveCrossOrder =
      getDefaultValuesFromLocalStorage({
        key: KEY_FOR_IS_CROSS_ORDER[serviceId],
        defaultValue: 'true',
      }) === 'true';
    const innerCrossOrder = isSellDisabled ? !isSellDisabled : saveCrossOrder;
    dispatch(toggleUserSettingCrossOrder({ value: innerCrossOrder, serviceId }));
  }, [dispatch, isSellDisabled, selectedCurrencyId, serviceId]);

  const [buySell, changeBuySell] = useBuySellInputCreateOrder(ORDER_TYPES.MARKET_ORDER.name);
  useBuySellOptionRestrictions(allowBuyFlg, allowSellFlg, buySell, changeBuySell);

  const [count, changeCount] = useInputCreateOrder(
    ORDER_TYPES.MARKET_ORDER.name,
    ORDER_TYPES.MARKET_ORDER.inputs.COUNT,
    quantityPrecision,
    true,
  );

  const validateSelectedManualTradeOrder = useValidateSelectedManualTradeOrder();
  const validateSelectedManualTradeOrderRef = useRef({});
  useEffect(() => {
    validateSelectedManualTradeOrderRef.current = { validateSelectedManualTradeOrder };
  }, [validateSelectedManualTradeOrder]);

  // validate all values on mount
  useEffect(() => {
    const { validateSelectedManualTradeOrder: validate } = validateSelectedManualTradeOrderRef.current;
    if (validate) {
      validate();
    }
  }, []);

  const isMarkupTarget = useIsMarkupTarget({
    instrumentId: selectedCurrencyId,
    orderType: ORDER_METHOD_MAIN.MARKET.ID,
    orderAmount: count,
  });

  const fifoTableValue = useManualTradeFifoTableLogic();

  const fifoTable = useCallback(() => {
    return (
      <table className={classNames('default-bg', styles.buySellTable)}>
        <thead>
          <tr className={styles.buySellTitle}>
            <td>{fifoTableValue.sell.title}</td>
            <td> </td>
            <td>{fifoTableValue.buy.title}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.buySellValue}>{fifoTableValue.sell.quantity}</td>
            <td className={styles.buySellLabel}>{fifoTableValue.classification.quantity}</td>
            <td className={styles.buySellValue}>{fifoTableValue.buy.quantity}</td>
          </tr>
          <tr>
            <td className={styles.avgValue}>{fifoTableValue.sell.averageTradePrice}</td>
            <td className={styles.avgTitle}>{fifoTableValue.classification.averageTradePrice}</td>
            <td className={styles.avgValue}>{fifoTableValue.buy.averageTradePrice}</td>
          </tr>
          <tr>
            <td className={styles.buySellValue} style={{ color: fifoTableValue.sell.style }}>
              {fifoTableValue.sell.valuationGainLoss}
            </td>
            <td className={styles.buySellLabel}>{fifoTableValue.classification.valuationGainLoss}</td>
            <td className={styles.buySellValue} style={{ color: fifoTableValue.buy.style }}>
              {fifoTableValue.buy.valuationGainLoss}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }, [fifoTableValue]);

  return (
    <div className={styles.allWrapper}>
      <div className={styles.tableWrapper}>{fifoTable()}</div>
      <div className={styles.wrapper}>
        <div className={styles.countRow}>
          <QuantityLabel
            serviceId={serviceId}
            quantityUnit={quantityUnit}
            quantityUnitConvFactor={instrumentSetting.quantityUnitConvFactor}
          />
          <InputNumber
            value={count}
            name={COUNT_INPUT}
            onChange={changeCount}
            type="number"
            step={isETF ? quantityPrecision : getFxCfdQuantityStep}
            onlyIntegerAllowed={quantityIsInteger}
          />
        </div>
        {isMarkupTarget && (
          <div className={styles.makeupsWarningRow}>
            <div className={styles.inner}>
              数量が100万通貨を超える場合、約定価格に大口マークアップが加算されます。詳しくは
              <a className={styles.button} href={MARKUPS_URL} target="_blank" rel="noopen noreferrer">
                こちら
              </a>
            </div>
          </div>
        )}

        <div className={styles.countRow}>
          両建
          <Switch checked={isCrossOrder} disabled={isSellDisabled} onChange={confirmHandleChangeCrossOrder} />
        </div>
        <div className={styles.countRow}>
          確認省略
          <Switch checked={withoutConfirmation} onChange={handleChangeWithoutConfirmation} />
        </div>
      </div>
    </div>
  );
};

export default memo(SideMenuOptionsMarkerOrder);
