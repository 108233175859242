import { createAdditionalParams, get, invokeApi } from '.';

export const getMarkups = async ({ serviceId, instrumentId, orderType }) => {
  const requestQueries = [];
  if (instrumentId) {
    requestQueries.push(`instrumentId=${instrumentId}`);
  }
  if (orderType) {
    requestQueries.push(`orderType=${orderType}`);
  }
  const requestQuery = requestQueries.length > 0 ? `?${requestQueries.join('&')}` : '';

  const additionalParams = await createAdditionalParams();
  return invokeApi({}, `${serviceId}/markups${requestQuery}`, get, additionalParams);
};
